import { fabric } from 'fabric';
import { Device, FontDataLight } from "repix-common";
import { CanvasOrientation } from '../fabric';
import uniqBy from 'lodash/uniqBy';

interface LoadDeviceArgs {
  device: Device;
  color?: string;
  screenshot?: string;
  orientation?: CanvasOrientation;
  defaultFont?: FontDataLight;
}

export interface FontLoadInfo {
  id: string;
  fontFamily: string;
  type: 'system' | 'user';
  css: string;
}

export const extractFonts = (data: any) => {

  const fontsToLoad: FontLoadInfo[] = [];

  try {
    const objects = data.objects as fabric.Object[];
    for (const obj of objects) {
      if (obj.type === 'group') {
        fontsToLoad.push(...extractFonts(obj));
      }
      if (obj.type !== 'textbox' && obj.type !== 'text') {
        continue;
      }

      const text = obj as fabric.Textbox;

      if (text.fontFamily && text.data?.fontId && text.data?.type) {

        fontsToLoad.push({
          fontFamily: text.fontFamily,
          type: text.data.type,
          css: text.data.css,
          id: text.data.fontId
        });
      }
    }
  }
  catch (e) {
    console.error(e);
  }

  return uniqBy(fontsToLoad, 'id');
}

export const loadImage = async (url: string): Promise<fabric.Image> => {
  return new Promise((resolve) => {
    fabric.Image.fromURL(url, (img: fabric.Image) => {
      resolve(img);
    }, {
      crossOrigin: 'anonymous',
    })
  })
}

export const loadSvg = async (url: string): Promise<fabric.Group> => {
  return new Promise(async (resolve) => {
    fabric.loadSVGFromURL(url, (svg, options) => {
      const obj = fabric.util.groupSVGElements(svg, options);
      obj.name = 'svg';
      resolve(obj as fabric.Group);
    })
  })
}

export const loadSvgStr = async (url: string): Promise<fabric.Group> => {
  return new Promise(async (resolve) => {
    const data = await fetch(url, {
      method: 'GET',
    });
    const svg = await data.text();
    // console.log('svg', svg);
    fabric.loadSVGFromString(svg, (svg, options) => {
      const obj = fabric.util.groupSVGElements(svg, options);
      obj.name = 'svg';
      resolve(obj as fabric.Group);
    })
  })
}


export const createScreenshotPlaceholder = (device: Device, orientation: CanvasOrientation, font?: FontDataLight): fabric.Object => {

  const imgWidth = device.images[ device.mainImage ].width;
  const imgHeight = device.images[ device.mainImage ].height;

  const options: fabric.TextOptions = {
    originX: 'center',
    originY: 'center',
    textAlign: 'center',
    left: orientation === 'portrait' ? imgWidth / 2 : imgHeight / 2,
    top: orientation === 'portrait' ? imgHeight / 2 : imgWidth / 2 * -1,
    fill: '#ccc',
    fontSize: imgWidth / 20,
  };

  if (font) {
    options.fontFamily = font.fontFamily;
    options.charSpacing = 0.1;
    options.data = {
      fontId: font.id,
      css: font.css,
      type: font.type,
    }
  }

  const text = new fabric.Text(`Screenshot\n${device.screenSize.width}px by ${device.screenSize.height}px`, options);

  let group: fabric.Group = new fabric.Group();

  if (device.background.type === 'rect') {
    const rect = new fabric.Rect({
      width: device.background.width,
      height: device.background.height,
      rx: device.background.borderRadius,
      ry: device.background.borderRadius,
      centeredRotation: true,
      top: orientation === 'portrait' ? device.background.top : device.background.left * -1,
      left: orientation === 'portrait' ? device.background.left : device.background.top,
      angle: orientation === 'portrait' ? 0 : -90,
    })

    rect.setGradient('fill', {
      x1: device.background.width / 2,
      y1: 0,
      x2: device.background.width / 2,
      y2: device.background.height,
      colorStops: {
        0: '#555',
        1: '#222',
      }
    });
    group = new fabric.Group([ rect, text ])
  }
  else if (device.background.type === 'circle') {
    const rect = new fabric.Circle({
      centeredRotation: true,
      top: orientation === 'portrait' ? device.background.top : device.background.left * -1,
      left: orientation === 'portrait' ? device.background.left : device.background.top,
      angle: orientation === 'portrait' ? 0 : -90,
      radius: device.background.radius,
    })

    rect.setGradient('fill', {
      x1: device.background.radius / 2,
      y1: 0,
      x2: device.background.radius / 2,
      y2: device.background.radius,
      colorStops: {
        0: '#555',
        1: '#222',
      }
    });
    group = new fabric.Group([ rect, text ])
  }

  return group;
  // return rect;
}

export const loadDevice = async ({ device, color, screenshot, orientation = 'portrait', defaultFont }: LoadDeviceArgs): Promise<fabric.Group> => {

  return new Promise((resolve, reject) => {
    const img = color ? device.images[ color ] : device.images[ device.mainImage ];

    fabric.Image.fromURL(`${process.env.CDN_URL}/${img.path}`, function (deviceImage: fabric.Image) {



      deviceImage.set({
        name: 'device_image',
        fill: 'red',
        globalCompositeOperation: 'nonzero',
        centeredRotation: true,
        angle: orientation === 'portrait' ? 0 : -90,
      });



      if (!screenshot) {
        const screenshotPlaceholder = createScreenshotPlaceholder(device, orientation, defaultFont);
        const group = new fabric.Group([
          screenshotPlaceholder,
          deviceImage
        ], {
          name: 'device',
          data: {
            id: device.id,
            color: color || device.mainImage,
            orientation,
          }
        });
        resolve(group);

      }
      else {

        fabric.Image.fromURL(screenshot, (screenshotImage: fabric.Image) => {

          if (device.background.type === 'rect') {
            screenshotImage.set({
              width: orientation === 'portrait' ? device.background.width : device.background.height,
              height: orientation === 'portrait' ? device.background.height : device.background.width,
              left: orientation === 'portrait' ? device.background.left : device.background.top,
              top: orientation === 'portrait' ? device.background.top : (device.background.left + device.background.width) * -1,
            })

            screenshotImage.set({
              clipPath: new fabric.Rect({
                width: device.background.width,
                height: device.background.height,
                rx: device.background.borderRadius,
                ry: device.background.borderRadius,
                originX: 'center',
                originY: 'center',
                angle: orientation === 'portrait' ? 0 : -90,
              })
            })
          }
          else if (device.background.type === 'circle') {
            screenshotImage.set({
              width: device.background.radius * 2,
              height: device.background.radius * 2,
              left: orientation === 'portrait' ? device.background.left : device.background.top,
              top: orientation === 'portrait' ? device.background.top : (device.background.left + (device.background.radius * 2)) * -1,
            })

            screenshotImage.set({
              clipPath: new fabric.Circle({
                radius: device.background.radius,
                originX: 'center',
                originY: 'center',
                angle: orientation === 'portrait' ? 0 : -90,
              })
            })
          }


          screenshotImage.set({
            name: 'screenshot_image'
          });
          const group = new fabric.Group([
            screenshotImage,
            deviceImage
          ], {
            name: 'device',
            data: {
              id: device.id,
              color: color || device.mainImage,
              orientation,
              screenshot,
            }
          });

          resolve(group);
        }, { crossOrigin: 'anonymous' });
      }
    }, {
      crossOrigin: 'anonymous',
    });
  })
}

// export const addSnapping = (instance: fabric.Canvas) => {
//   instance.enableSnapping = function () {
//     let snapVVisible = false;
//     let snapHVisible = false;

//     const getVSnapLine = () => {
//       return new fabric.Line([
//         this.getScaledWidth() / 2,
//         0,
//         this.getScaledWidth() / 2,
//         this.getScaledHeight(),
//       ], {
//         stroke: 'blue',
//         name: 'v_snap_line',
//         strokeDashArray: [ 5, 5 ],
//       });
//     }

//     const getHSnapLine = () => {
//       return new fabric.Line([
//         0,
//         this.getScaledHeight() / 2,
//         this.getScaledWidth(),
//         this.getScaledHeight() / 2,
//       ], {
//         name: 'h_snap_line',
//         stroke: 'blue',
//         strokeDashArray: [ 5, 5 ],
//       });
//     }

//     const getTop = (object: fabric.Object) => {
//       if (!object.height || !object.top) {
//         return 0;
//       }

//       if (object.originY === 'center') {
//         return object.top;
//       }
//       else {
//         return object.top + (object.getScaledHeight() / 2);
//       }
//     }
//     const getLeft = (object: fabric.Object) => {
//       if (!object.width || !object.left) {
//         return 0;
//       }

//       if (object.originX === 'center') {
//         return object.left;
//       }
//       else {
//         return object.left + (object.getScaledWidth() / 2);
//       }
//     }


//     this.on('object:moving', (e) => {
//       if (!e.target) {
//         return;
//       }

//       const centerH = this.getScaledWidth() / 2;
//       const centerV = this.getScaledHeight() / 2;
//       const top = getTop(e.target);
//       const left = getLeft(e.target);
//       const lineV = getVSnapLine();
//       const lineH = getHSnapLine();

//       if (Math.abs(left - centerH) <= 25) {
//         e.target.viewportCenterH().setCoords();
//         if (!snapHVisible) {
//           snapHVisible = true;
//           this.add(lineV);
//           lineV.bringToFront();
//           this.renderAll();
//         }
//       }
//       else {
//         if (snapHVisible) {
//           snapHVisible = false;
//           this.removeSnapLines([ 'v_snap_line' ]);
//         }
//       }


//       if (Math.abs(top - centerV) <= 25) {
//         e.target.viewportCenterV().setCoords();
//         if (!snapVVisible) {
//           snapVVisible = true;
//           this.add(lineH);
//           lineH.bringToFront();
//           this.renderAll();
//         }
//       }
//       else {
//         if (snapVVisible) {
//           snapVVisible = false;
//           this.removeSnapLines([ 'h_snap_line' ]);
//         }
//       }
//     })

//     this.removeSnapLines = (names) => {
//       const objects = this.getObjects();
//       for (const obj of objects) {
//         if (obj.name && names.includes(obj.name)) {
//           this.remove(obj);
//         }
//       }
//     }

//     this.on('object:moved', (e) => {
//       this.removeSnapLines([ 'v_snap_line', 'h_snap_line' ]);
//       snapVVisible = false;
//       snapHVisible = false;
//     })
//   }
// }

export type GradientDirection = 'top_to_bottom' | 'bottom_to_top' | 'left_to_right' | 'right_to_left';


export const coordsToDirection = (coords?: fabric.IGradientOptionsCoords): GradientDirection => {
  if (!coords || typeof coords.x1 === 'undefined' || typeof coords.x2 === 'undefined' || typeof coords.y1 === 'undefined' || typeof coords.y2 === 'undefined') {
    return 'top_to_bottom';
  }

  // if ()
  if (coords.x1 === coords.x2) {
    //vertical
    if (coords.y1 < coords.y2) {
      return 'top_to_bottom';
    }
    else {
      return 'bottom_to_top'
    }
  }
  else if (coords.y1 === coords.y2) {
    //horizontal
    if (coords.x1 < coords.x2) {
      return 'left_to_right';
    }
    else {
      return 'right_to_left';
    }
  }
  return 'top_to_bottom';
}

export const directionToCoords = (direction: GradientDirection, canvas: fabric.Canvas): fabric.IGradientOptionsCoords => {
  const width = canvas.getScaledWidth();
  const height = canvas.getScaledHeight();
  if (direction === 'bottom_to_top') {
    return {
      x1: width / 2,
      y1: height,
      x2: width / 2,
      y2: 0,
    }
  }
  else if (direction === 'left_to_right') {
    return {
      x1: 0,
      y1: height / 2,
      x2: width,
      y2: height / 2,
    }
  }
  else if (direction === 'right_to_left') {
    return {
      x1: width,
      y1: height / 2,
      x2: 0,
      y2: height / 2,
    }
  }
  else {
    return {
      x1: width / 2,
      y1: 0,
      x2: width / 2,
      y2: height,
    }
  }
}