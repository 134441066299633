import React, { useEffect } from "react";
import { useCanvas } from "./CanvasProvider";

export const KeyboardControls: React.FC = () => {

  const { activeCanvas, activeIndex, selectedObjects } = useCanvas();

  useEffect(() => {
    let saveTimer: any = null;

    if (selectedObjects && selectedObjects.length > 0) {

      const moveObjects = (delta: { top?: number, left?: number }) => {
        if (!selectedObjects) {
          return;
        }

        for (const obj of selectedObjects) {
          const top = (obj.top || 0) + (delta.top || 0);
          const left = (obj.left || 0) + (delta.left || 0);
          obj.set({ top, left });
          obj.setCoords();
        }
        activeCanvas?.renderAll();
        saveTimer && clearTimeout(saveTimer);

        saveTimer = setTimeout(() => {
          activeCanvas?.updated();
          saveTimer = null;
        }, 300);
      }


      const deleteObjects = () => {
        if (!selectedObjects) {
          return;
        }

        for (const obj of selectedObjects) {
          activeCanvas?.remove(obj);
        }
        activeCanvas?.updated();
      }

      const onKeyDown = (e: KeyboardEvent) => {
        const tagName = (e.target as any).tagName as string;
        //do not trigger keyboard events when for exmaple the textbox is focused
        if (tagName.toLowerCase() !== 'div') {
          return;
        }

        console.log(e.key);

        switch (e.key) {
          case 'ArrowDown':
            moveObjects({ top: 1 })
            return;
          case 'ArrowUp':
            moveObjects({ top: -1 })
            return;
          case 'ArrowLeft':
            moveObjects({ left: -1 })
            return;
          case 'ArrowRight':
            moveObjects({ left: 1 })
            return;
          case 'Delete':
          case 'Backspace':
            deleteObjects();
            return;
        }
      }

      window.addEventListener('keydown', onKeyDown);

      return () => {
        window.removeEventListener('keydown', onKeyDown);
      }

    }

  }, [ selectedObjects ]);

  return null;
};
