import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, IconButton, Tooltip } from "@material-ui/core";

import IconImage from '@material-ui/icons/InsertPhotoOutlined';
import IconGradient from '@material-ui/icons/GradientOutlined';


import ColorPicker from './properties/ColorPicker';
import GradientPicker from './properties/GradientPicker';
import { useCanvas } from "../CanvasProvider";
import { fabric } from "fabric";

import ImagesDialog from '../../ImagePickerDialog/ImagePickerDialog';
import { UserImage, StockPhoto } from "repix-common";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootCanvasProperites: {
      },
    }
  ),
);

const CanvasProperites: React.FC = () => {
  const classes = useStyles();

  const { activeCanvas } = useCanvas();

  const [ imagesDialogOpen, setImagesDialogOpen ] = useState(false);

  const openImagesDialog = () => {
    setImagesDialogOpen(true);
  }
  const closeImagesDialog = () => {
    setImagesDialogOpen(false);
  }

  if (!activeCanvas) {
    return null;
  }

  const onColorChange = (color: string) => {
    activeCanvas.setBgColor(color);
    activeCanvas.updated();
  }

  const onGradientChange = (gradient: fabric.Gradient) => {
    activeCanvas.setBgColor(gradient);
    activeCanvas.updated();
  }

  const onSelectBgImage = (selected: { image?: UserImage, stockPhoto?: StockPhoto }) => {
    let imgUrl: string;
    if (selected.image) {
      imgUrl = `${process.env.CDN_URL}/${selected.image?.path}`;
    }
    else {
      imgUrl = `${process.env.CDN_URL}/${selected.stockPhoto?.large.path}`;
    }
    activeCanvas.setBgImage(imgUrl);
    activeCanvas.updated();
    closeImagesDialog();
  }

  const bgColorValue = typeof activeCanvas.backgroundColor === 'string' ? activeCanvas.backgroundColor : 'rgba(255, 255, 255, 0)';

  return (
    <div className={classes.rootCanvasProperites}>
      <ColorPicker color={bgColorValue} onChange={onColorChange} title="Background Color" />

      <GradientPicker onChange={onGradientChange} title="Background Gradient" value={activeCanvas.backgroundColor as any} />

      <Tooltip title="Background Image">
        <IconButton onClick={openImagesDialog}>
          <IconImage />
        </IconButton>
      </Tooltip>
      <ImagesDialog
        open={imagesDialogOpen}
        onSelect={onSelectBgImage}
        onCancel={closeImagesDialog}
        imageType="background"
        stockPhotosCategory="background"
      />
    </div>
  );
};

export default CanvasProperites;