import React, { useState, useEffect } from "react";
import orderBy from 'lodash/orderBy';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Popover, MenuItem, ListItem, ListItemText, Collapse, } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useCanvas } from "./CanvasProvider";

import { CanvasSizeType } from 'repix-common';

export const canvasSizeTypes: CanvasSizeType[] = [
  {
    type: 'ios',
    name: 'iOS',
    subtypes: {
      'iphone_6.5': {
        order: 0,
        subtype: 'iphone_6.5',
        name: 'iPhone 6.5"',
        width: 1242,
        height: 2688,
      },
      'iphone_5.8': {
        order: 1,
        subtype: 'iphone_5.8',
        name: 'iPhone 5.8"',
        width: 1125,
        height: 2436,
      },
      'iphone_5.5': {
        order: 2,
        subtype: 'iphone_5.5',
        name: 'iPhone 5.5"',
        width: 1242,
        height: 2208,
      },
      'iphone_4.7': {
        order: 3,
        subtype: 'iphone_4.7',
        name: 'iPhone 4.7"',
        width: 750,
        height: 1334,
      },
      'iphone_4': {
        order: 4,
        subtype: 'iphone_4',
        name: 'iPhone 4"',
        width: 640,
        height: 1096,
      },
      'ipad_pro_12.9': {
        order: 5,
        subtype: 'ipad_pro_12.9',
        // name: 'iPad Pro 12.9" (2nd & 3rd gen)',
        name: 'iPad Pro 12.9"',
        width: 2048,
        height: 2732,
      },
      'ipad_pro_11': {
        order: 6,
        subtype: 'ipad_pro_11',
        name: 'iPad Pro 11"',
        width: 1668,
        height: 2388,
      },
      'ipad_10.5': {
        order: 7,
        subtype: 'ipad_10.5',
        // name: 'iPad 10.5" (iPad Pro, iPad Air)',
        name: 'iPad 10.5"',
        width: 1668,
        height: 2224,
      },
      'ipad_9.7': {
        order: 8,
        subtype: 'ipad_9.7',
        // name: 'iPad 9.7" (iPad, iPad mini)',
        name: 'iPad 9.7"',
        width: 1536,
        height: 2008,
      },
    }
  },
  {
    type: 'android',
    name: 'Android',
    subtypes: {
      'smartphone': {
        order: 0,
        subtype: 'smartphone',
        name: 'Smartphone',
        width: 1080,
        height: 2280,
      },
      'smartphone_xl': {
        order: 0,
        subtype: 'smartphone_xl',
        name: 'Smartphone XL',
        width: 1440,
        height: 3040,
      },
      'tablet': {
        order: 1,
        subtype: 'tablet',
        name: 'Tablet',
        width: 1300,
        height: 2000,
      },
    }
  },
]


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootFilterToggles: {
        flex: 'auto 0 0',
        marginRight: theme.spacing(2),
      },
      platformBtn: {
        // width: 120,
        padding: theme.spacing(1, 2),
        textTransform: 'none',
      },
      nested: {
        paddingLeft: theme.spacing(4),
      },
    }
  ),
);


interface Props {
  listMode?: boolean;
  onCloseDrawer?: () => void;
}


const FilterToggles: React.FC<Props> = ({ listMode, onCloseDrawer }) => {
  const classes = useStyles();
  const { sizeFilter, setSizeFilter, canvases } = useCanvas();

  const [ anchorEl, setAnchorEl ] = React.useState<{ [ type: string ]: null | HTMLElement }>({});
  const [ counts, setCounts ] = useState<{ [ key: string ]: number }>({});
  const [ value, setValue ] = useState<{ type: string, subtype: string }>();

  const [ expanded, setExpanded ] = useState<{ [ type: string ]: boolean }>({});

  const toggleExpanded = (type: string) => {
    setExpanded({
      ...expanded,
      [ type ]: !Boolean(expanded[ type ]),
    })
  }


  useEffect(() => {
    const newCounts = { ...counts };
    let newType = '', newSubtype = '';
    canvasSizeTypes.forEach(type => {
      for (const subtype in type.subtypes) {
        const sizeArr = [ type.subtypes[ subtype ].width, type.subtypes[ subtype ].height ];
        if (sizeArr.includes(sizeFilter.width) && sizeArr.includes(sizeFilter.height)) {
          newType = type.type;
          newSubtype = subtype;
        }

        const count = canvases.filter(canvas => {
          return sizeArr.includes(canvas.width) && sizeArr.includes(canvas.height)
        }).length;

        newCounts[ `${type.type}_${subtype}` ] = count;
      }
    })

    setCounts(newCounts);
    setValue({ type: newType, subtype: newSubtype });

    // if (!Boolean(expanded[newType])) {
    //   setExpanded({
    //     ...expanded,
    //     [ newType ]: true
    //   })
    // }
  }, [ canvases.length, sizeFilter ]);

  const hanldeChange = (type: string, width: number, height: number) => () => {
    setSizeFilter({ width, height });
    setAnchorEl({
      ...anchorEl,
      [ type ]: null
    });

    onCloseDrawer && onCloseDrawer();
  };

  const handleOpen = (type: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl({
      ...anchorEl,
      [ type ]: event.currentTarget
    });
  };

  const handleClose = (type: string) => () => {
    setAnchorEl({
      ...anchorEl,
      [ type ]: null
    });
  }

  if (listMode) {
    return (
      <React.Fragment>
        {
          canvasSizeTypes.map((type) => {
            const selected = value && value.type === type.type;
            const label = selected && value ? `${type.name} - ${type.subtypes[ value.subtype ].name}` : type.name;
            const subtypes = Object.values(type.subtypes);
            return (
              <React.Fragment key={type.type}>
                <ListItem button onClick={() => toggleExpanded(type.type)}>
                  <ListItemText primary={label} />
                  {Boolean(expanded[ type.type ]) ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={Boolean(expanded[ type.type ])} timeout="auto" unmountOnExit>
                  {
                    subtypes.map(subtype => {
                      return (
                        <ListItem
                          className={classes.nested}
                          key={subtype.name}
                          button
                          onClick={hanldeChange(type.type, subtype.width, subtype.height)}
                          selected={value?.subtype === subtype.subtype}
                        >
                          <ListItemText primary={`${subtype.name} (${counts[ `${type.type}_${subtype.subtype}` ]})`} />
                        </ListItem>
                      )
                    })
                  }
                </Collapse>
              </React.Fragment>
            )
          })
        }
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ToggleButtonGroup
        className={classes.rootFilterToggles}
        value={value?.type}
        exclusive
        aria-label="text alignment"
      >
        {
          canvasSizeTypes.map((type) => {
            const selected = value && value.type === type.type;
            const label = selected && value ? `${type.name} - ${type.subtypes[ value.subtype ].name}` : type.name;
            return (
              <ToggleButton
                value={type.type}
                key={type.type}
                size="small"
                className={classes.platformBtn}
                selected={selected}
                onClick={handleOpen(type.type)}
              >
                {label}
                <ArrowDropDownIcon />
              </ToggleButton>
            )
          })
        }
      </ToggleButtonGroup>

      {
        canvasSizeTypes.map((type) => {
          const subtypes = orderBy(type.subtypes, 'order', 'asc');
          return (
            <Popover
              key={type.name}
              open={Boolean(anchorEl[ type.type ])}
              anchorEl={anchorEl[ type.type ]}
              onClose={handleClose(type.type)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              disablePortal={false}
            >
              {
                subtypes.map(subtype => (
                  <MenuItem key={subtype.name} onClick={hanldeChange(type.type, subtype.width, subtype.height)}>{subtype.name} ({counts[ `${type.type}_${subtype.subtype}` ]})</MenuItem>
                ))
              }

            </Popover>
          )

        })
      }
    </React.Fragment>
  );
};

export default FilterToggles;