import React, { useState } from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
    }
  ),
);

interface Props {
  maxSize?: number;
  onChange: (file: File, src: string) => void;
  disabled?: boolean;
  label?: string;
  className?: string;
  fileType?: string,
}

const LocalFile: React.FC<Props> = ({
  onChange,
  label = 'Choose a file...',
  disabled,
  className,
  fileType = 'image/*',
}) => {
  const classes = useStyles();

  const onFileChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (e.target && e.target.result) {
          onChange(files[ 0 ], e.target.result)
        }
      }
      reader.readAsDataURL(files[ 0 ]);
    }
  }

  return (
    <Button variant="outlined" component="label" disabled={disabled} className={className}>
      {label}
      <input
        type="file"
        accept={fileType}
        style={{ display: "none" }}
        onChange={(e) => { onFileChange(e.currentTarget.files) }}
        onClick={e => (e.target as any).value = null}
      />
    </Button>
  );
};

export default LocalFile;