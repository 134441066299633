import React from "react";
import clsx from 'clsx';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Box, Typography } from "@material-ui/core";
import { TemplateData } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTemplateItem: {
        border: `3px solid transparent`,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1),
        cursor: 'pointer',
      },
      selected: {
        border: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
      },
      list: {
        display: 'flex',
        overflow: 'scroll',
      },
      img: {
        flex: '0 0 auto',
        marginRight: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
      }
    }
  ),
);

interface Props {
  template: TemplateData;
  selected?: boolean;
  className?: string;
  onClick?: (template: TemplateData) => void;
  showName?: boolean;
}

const TemplateItem: React.FC<Props> = ({ template, selected, className, onClick, showName }) => {
  const classes = useStyles();

  const _onClick = () => {
    onClick && onClick(template);
  }


  return (
    <div onClick={_onClick} className={clsx(classes.rootTemplateItem, className, { [ classes.selected ]: selected })}>
      {
        showName ? (
          <Box>
            <Typography>{template.name}</Typography>
          </Box>
        ) : null
      }
      <div className={classes.list}>
        {
          template.thumbs.map(thumb => {
            const height = 400;
            const width = (height / thumb.height) * thumb.width;
            return (
              <img
                key={thumb.path}
                className={classes.img}
                src={`${process.env.CDN_URL}/${thumb.path}`}
                style={{ width, height }} />
            )
          })
        }
      </div>
    </div>
  );
};

export default TemplateItem;