import React, { useEffect, useState } from "react";
import color from 'color';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, IconButton, Popover } from "@material-ui/core";
import { SketchPicker, RGBColor } from 'react-color';

import { useCanvas } from "../../CanvasProvider";
import uniq from 'lodash/uniq';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootGroupColors: {
        display: 'flex',
        flexDirection: 'row',
      },

      colorItem: {
        width: 20,
        height: 20,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(0.5),
      },
    }
  ),
);

const GroupColors: React.FC = () => {
  const classes = useStyles();
  const { activeCanvas, selectedObjects } = useCanvas();

  const [ colors, setColors ] = useState<string[]>([]);

  const [ currentColor, setCurrentColor ] = useState<{ anchor: null | HTMLElement, color: string }>({
    anchor: null,
    color: '',
  })



  const close = () => {
    setCurrentColor({
      anchor: null,
      color: ''
    })
    updateColors();
  }

  const rgbToString = (color: RGBColor) => {
    return `rgb(${color.r}, ${color.g}, ${color.b}, ${color.a ? color.a : 1})`
  }

  const onColorChange = (fromColor: string, toColor: string) => {
    if (!selectedObjects) {
      return;
    }

    const obj = selectedObjects[ 0 ];

    if (obj.type === 'group') {
      (obj as fabric.Group).forEachObject((obj) => {
        if (!obj.fill) {
          return;
        }

        if (color(obj.fill).rgb().toString() === color(fromColor).rgb().toString()) {
          obj.set({
            fill: toColor,
          });
        }
      })
    }
    else if (obj.type === 'path') {
      console.log(JSON.stringify(obj));
      (obj as fabric.Path).set({
        fill: toColor,
      })
    }

    activeCanvas?.updated();
  }


  useEffect(() => {

    updateColors();

  }, [ selectedObjects, activeCanvas ]);

  const updateColors = () => {
    if (!activeCanvas || !selectedObjects) {
      return;
    }

    const obj = selectedObjects[ 0 ];

    const _colors: string[] = [];

    if (obj.type === 'group') {
      (obj as fabric.Group).forEachObject((item) => {
        if (item.fill) {
          _colors.push(item.fill as string);
        }
      })
    }
    else if (obj.type === 'path') {
      if (obj.fill) {
        _colors.push(obj.fill as string)
      }
    }

    setColors(uniq(_colors));
  }



  return (
    <div className={classes.rootGroupColors}>
      {
        colors.map(colorValue => {
          const colorStr = colorValue ? color(colorValue).rgb().string() : colorValue;

          return (
            <IconButton key={colorStr} onClick={(e) => {
              setCurrentColor({
                anchor: e.currentTarget,
                color: colorStr,
              })
            }}>
              <div className={classes.colorItem} style={{ backgroundColor: colorStr }} />
            </IconButton>
          )
        })
      }

      <Popover
        open={Boolean(currentColor.anchor)}
        anchorEl={currentColor.anchor}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker
          color={currentColor.color}
          onChangeComplete={(props) => {
            onColorChange(currentColor.color, rgbToString(props.rgb))
            // onChange(rgbToString(props.rgb));
          }}
        />
      </Popover>
    </div>
  );
};

export default GroupColors;