import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, IconButton, Tooltip } from "@material-ui/core";
import IconDelete from '@material-ui/icons/DeleteOutline';
import { useCanvas } from "../../CanvasProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootDeleteButton: {
        // marginRight: theme.spacing(2),
      },
    }
  ),
);

const DeleteButton: React.FC = () => {
  const classes = useStyles();
  const { selectedObjects, activeCanvas } = useCanvas();

  const onClick = () => {
    if (!selectedObjects) {
      return;
    }

    selectedObjects?.length > 0 && activeCanvas?.remove(selectedObjects[ 0 ]);
    activeCanvas?.updated();
  }

  return (
    <Tooltip title="Delete">
      <IconButton className={classes.rootDeleteButton} onClick={onClick}>
        <IconDelete />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteButton;