import React from "react";
import loadable from '@loadable/component';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Toolbar, Typography } from "@material-ui/core";
import { useCanvas } from "../CanvasProvider";

import CanvasProperties from './CanvasProperties';
import GroupProperties from './GroupProperties';
import DeviceProperties from './DeviceProperties';
import ImageProperties from './ImageProperties';
import SvgProperties from './SvgProperties';
import ElementProperties from './ElementProperties';
import ShapeProperties from './ShapeProperties';

const TextProperties = loadable(() => import('./TextProperties'));

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      objectProperties: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        maxWidth: '100vw',
        overflow: 'auto',
        height: 64,
      },
    }
  ),
);

export const ObjectProperties: React.FC = () => {
  const classes = useStyles();

  const { selectedObjects, activeIndex, flags } = useCanvas();

  const renderContent = () => {
    if (typeof activeIndex === 'undefined') {
      return null;
    }

    if (!selectedObjects || selectedObjects.length === 0) {
      return <CanvasProperties />;
    }
    else if (selectedObjects.length > 1) {
      return <GroupProperties />;
    }

    const activeObject = selectedObjects[ 0 ];

    if (activeObject.type === 'textbox' || activeObject.type === 'text') {
      return <TextProperties />;
    }
    else if (activeObject.type === 'group' && activeObject.name === 'device') {
      return <DeviceProperties />;
    }
    else if (activeObject.type === 'group' && activeObject.name === 'svg') {
      return <SvgProperties />;
    }
    else if (activeObject.type === 'group' && activeObject.name === 'custom_group') {
      return <GroupProperties />;
    }
    else if (activeObject.type === 'image') {
      return <ImageProperties />;
    }
    else if (['group', 'path'].includes(activeObject.type || '') && activeObject.name === 'design_element') {
      return <ElementProperties />;
    }
    else if (activeObject.name?.startsWith('shape_')) {
      return <ShapeProperties />;
    }


    return null;
  }

  const isLineDrawing = flags.includes('line_drawing');


  return (
    <Toolbar className={classes.objectProperties}>
      {
        isLineDrawing ? <Typography variant="body2">Draw a line with mouse</Typography> : renderContent()
      }
    </Toolbar>
  );
};