import React from "react";
import clsx from 'clsx';
import { areEqual } from 'react-window';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, CircularProgress } from "@material-ui/core";
import { StockPhoto } from "repix-common";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootUserImg: {
        width: 250,
        height: 250,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(1),
        transition: 'all 200ms',
        border: `3px solid ${theme.palette.background.paper}`,
        flex: '0 0 auto',
      },
      selected: {
        border: `3px solid ${theme.palette.secondary.main}`,
      }
    }
  ),
);

interface Props {
  photo: StockPhoto;
  className?: string;
  selected?: boolean;
  onClick?: (image: StockPhoto) => void;
  large?: boolean;
  style?: any;
}
const StockPhotoImg: React.FC<Props> = ({ photo, className, onClick, selected, large, style }) => {
  const classes = useStyles();

  const imgPath = (large && photo.large ? photo.large?.path : photo.small?.path) || photo.original.path;

  const imgUrl = `${process.env.CDN_URL}/${imgPath}`;

  const localCache = useSelector((state: RootState) => state.stockPhotos.localCache);

  const src = localCache[ photo.id ] || imgUrl;

  const styles: any = {
    backgroundImage: `url(${src})`
  }

  if (onClick && photo.status === 'active') {
    styles.cursor = 'pointer';
  }


  console.log('stockPhoto render');
  return (
    <div
      className={clsx(classes.rootUserImg, className, { [ classes.selected ]: selected })}
      style={{
        ...styles,
        ...style
      }}
      onClick={() => {
        onClick && photo.status === 'active' && onClick(photo);
      }}
    >
      {
        photo.status === 'pending' ? <CircularProgress /> : null
      }
    </div>
  );
};

(StockPhotoImg as any).whyDidYouRender = true;
const MemoStockPhoto = React.memo(StockPhotoImg, areEqual);

// export default React.memo(StockPhotoImg, areEqual);
(MemoStockPhoto as any).whyDidYouRender = true;
export default MemoStockPhoto;
