import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, IconButton, Tooltip, Popover, Slider, TextField } from "@material-ui/core";
import { useCanvas } from "../CanvasProvider";
import CommonProperties from "./properties/CommonProperties";

import IconLineWeight from '@material-ui/icons/LineWeightOutlined';
import IconRoundedCorner from '@material-ui/icons/RoundedCornerRounded';

import ColorPicker from './properties/ColorPicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootShapeProperties: {
        display: 'flex',
      },
      popover: {
        width: 280,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1, 2.5),
        alignItems: 'flex-end',
        paddingTop: theme.spacing(5),
      },
      numberInput: {
        width: 100,
        marginBottom: theme.spacing(1),
      }
    }
  ),
);

const ShapeProperties: React.FC = () => {
  const classes = useStyles();
  const { selectedObjects, activeCanvas } = useCanvas();

  const [ anchorElBorderWidth, setAnchorElBorderWidth ] = React.useState<null | HTMLElement>(null);
  const [ anchorElBorderRadius, setAnchorElBorderRadius ] = React.useState<null | HTMLElement>(null);

  if (!selectedObjects) {
    return null;
  }

  const shape = selectedObjects[ 0 ];

  const setBgColor = (color: string) => {
    shape.set({
      fill: color
    });
    activeCanvas?.updated();
  }

  const setBorderColor = (color: string) => {
    shape.set({
      stroke: color
    });
    activeCanvas?.updated();
  }

  const openBorderWidth = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBorderWidth(anchorElBorderWidth ? null : event.currentTarget);
  };

  const closeBorderWidth = () => {
    setAnchorElBorderWidth(null);
  }

  const openBorderRadius = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBorderRadius(anchorElBorderRadius ? null : event.currentTarget);
  };

  const closeBorderRadius = () => {
    setAnchorElBorderRadius(null);
  }

  const setBorderWidth = (event: any, newValue: number | number[]) => {
    shape.set({
      strokeWidth: newValue as number
    });
    activeCanvas?.updated();
  }

  const setBorderRadius = (event: any, newValue: number | number[]) => {
    (shape as fabric.Rect).set({
      rx: newValue as number,
      ry: newValue as number,
    });
    activeCanvas?.updated();
  }


  return (
    <div className={classes.rootShapeProperties}>
      <CommonProperties />
      <ColorPicker title="Border Color" color={shape.stroke as string} onChange={setBorderColor} showColor />
      <IconButton onClick={openBorderWidth}>
        <Tooltip title="Border Thinkness">
          <IconLineWeight />
        </Tooltip>
      </IconButton>

      {
        shape.name === 'shape_rect' ? (
          <IconButton onClick={openBorderRadius}>
            <Tooltip title="Rounded Corners">
              <IconRoundedCorner />
            </Tooltip>
          </IconButton>
        ) : null
      }


      {
        shape.name === 'shape_line' ? null : <ColorPicker title="Fill Color" color={shape.fill as string} onChange={setBgColor} showColor />
      }

      <Popover
        open={Boolean(anchorElBorderWidth)}
        anchorEl={anchorElBorderWidth}
        onClose={closeBorderWidth}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popover
        }}
      >
        <Slider value={shape.strokeWidth as number} onChange={setBorderWidth} valueLabelDisplay="on" />
      </Popover>


      <Popover
        open={Boolean(anchorElBorderRadius)}
        anchorEl={anchorElBorderRadius}
        onClose={closeBorderRadius}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popover
        }}
      >
        <Slider value={(shape as fabric.Rect).rx as number} onChange={setBorderRadius} valueLabelDisplay="on" />
      </Popover>
    </div>
  );
};

export default ShapeProperties;