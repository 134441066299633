import React, { useRef, useState, useEffect } from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ButtonBase } from "@material-ui/core";

import IconCircle from './icons/circle.svg';
import IconSquare from './icons/square.svg';
import IconTriangle from './icons/triangle.svg';
import IconHexagon from './icons/hexagon.svg';
import IconLine from './icons/line.svg';

import { useCanvas } from "../CanvasProvider";
import { fabric } from "fabric";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTabShapes: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        height: 240,
      },
      button: {
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(3),
      },
      icon: {
        width: 60,
      },
      iconLarge: {
        width: 80,
      },
      lineIcon: {
        transform: 'rotate(-45deg)',
        '@media (max-width:1200px)': {
          display: 'none',
        }
      },
      btnActive: {
        backgroundColor: theme.palette.action.selected,
      }
    }
  ),
);

interface Props {
  horizontal?: boolean;
  onAdd?: () => void;
}

const TabShapes: React.FC<Props> = ({ horizontal, onAdd }) => {
  const classes = useStyles();
  const { activeCanvas, flags, addFlag, removeFlag } = useCanvas();

  const defaultSize = activeCanvas ? activeCanvas.getScaledWidth() / 3 : 200;

  const [ lineDrawing, setLineDrawing ] = useState<{ line?: fabric.Line, isDown?: boolean }>({});




  const addCircle = () => {
    onAdd && onAdd();
    const shape = new fabric.Circle({
      radius: defaultSize / 2,
      fill: '#ffffff',
      stroke: '#000000',
      strokeWidth: 5,
      name: 'shape_circle',
      strokeUniform: true,
    });
    activeCanvas?.add(shape);
  }

  const addRect = () => {
    onAdd && onAdd();
    const shape = new fabric.Rect({
      width: defaultSize,
      height: defaultSize,
      fill: '#ffffff',
      stroke: '#000000',
      strokeWidth: 5,
      name: 'shape_rect',
      strokeUniform: true,
      noScaleCache: false,
    });
    activeCanvas?.add(shape);
  }

  const addTriangle = () => {
    onAdd && onAdd();
    const shape = new fabric.Triangle({
      width: defaultSize,
      height: defaultSize,
      fill: '#ffffff',
      stroke: '#000000',
      strokeWidth: 5,
      name: 'shape_triangle',
      strokeUniform: true,
    });

    activeCanvas?.add(shape);
  }


  const addHexagon = () => {
    onAdd && onAdd();
    const size = defaultSize;
    const side = Math.round((size * Math.sqrt(2)) / (2 + Math.sqrt(2)));

    const points = [
      { x: -side / 2, y: size / 2 },
      { x: side / 2, y: size / 2 },
      { x: size / 2, y: side / 2 },
      { x: size / 2, y: -side / 2 },
      { x: side / 2, y: -size / 2 },
      { x: -side / 2, y: -size / 2 },
      { x: -size / 2, y: -side / 2 },
      { x: -size / 2, y: side / 2 } ];

    const shape = new fabric.Polygon(points, {
      left: 0,
      top: 0,
      fill: '#ffffff',
      stroke: '#000000',
      strokeWidth: 5,
      name: 'shape_hexagon',
      strokeUniform: true,
    });

    activeCanvas?.add(shape);
  }

  const isLineDrawing = flags.includes('line_drawing');

  useEffect(() => {
    if (!activeCanvas || !isLineDrawing) {
      return;
    }

    let isDown = false;
    let line: fabric.Line;

    const onMouseDown = (event: fabric.IEvent) => {
      const pointer = activeCanvas.getPointer(event.e);
      isDown = true;
      line = new fabric.Line([ pointer.x, pointer.y, pointer.x, pointer.y ], {
        strokeWidth: 5,
        stroke: '#000000',
        fill: '#000000',
        originX: 'center',
        originY: 'center',
        name: 'shape_line',
      });
      activeCanvas.add(line);
    }

    const onMouseMove = (event: fabric.IEvent) => {
      if (!isDown || !line) {
        return;
      }

      const pointer = activeCanvas.getPointer(event.e);
      line.set({ x2: pointer.x, y2: pointer.y });
      activeCanvas.renderAll();
    }

    const onMouseUp = (e: fabric.IEvent) => {
      isDown = false;
      line.setCoords();
      activeCanvas.setActiveObject(line);
      activeCanvas.updated();
      disableDrawing();
    }

    activeCanvas.on('mouse:down', onMouseDown);
    activeCanvas.on('mouse:move', onMouseMove);
    activeCanvas.on('mouse:up', onMouseUp);

    return () => {
      activeCanvas.off('mouse:down', onMouseDown);
      activeCanvas.off('mouse:move', onMouseMove);
      activeCanvas.off('mouse:up', onMouseUp);
    }

  }, [ isLineDrawing, activeCanvas ])

  const toggleLineDrawing = () => {
    if (flags.includes('line_drawing')) {
      disableDrawing();
    }
    else {
      enableDrawing();
      // onAdd && onAdd();
    }
  }

  const enableDrawing = () => {
    if (!activeCanvas) {
      return;
    }

    activeCanvas.discardActiveObject();
    activeCanvas.selection = false;
    activeCanvas.hoverCursor = 'crosshair';
    activeCanvas.defaultCursor = 'crosshair';
    activeCanvas.forEachObject(obj => {
      if (obj.selectable || typeof obj.selectable === 'undefined') {
        obj.selectable = false;
      }
    })
    activeCanvas.renderAll();
    addFlag('line_drawing');
  }

  const disableDrawing = () => {
    if (!activeCanvas) {
      return;
    }

    activeCanvas.selection = true;
    activeCanvas.hoverCursor = 'move';
    activeCanvas.defaultCursor = 'default';
    activeCanvas.forEachObject(obj => {
      if (obj.selectable === false) {
        obj.selectable = true;
      }
    })
    activeCanvas.renderAll();
    removeFlag('line_drawing');
  }

  return (
    <div className={classes.rootTabShapes}>

      <ButtonBase className={clsx(classes.button, { [ classes.btnActive ]: flags.includes('line_drawing') })} onClick={toggleLineDrawing}>
        <IconLine className={clsx(classes.icon, classes.lineIcon, { [ classes.iconLarge ]: horizontal })} />
      </ButtonBase>

      <ButtonBase className={classes.button} onClick={addCircle}>
        <IconCircle className={clsx(classes.icon, { [ classes.iconLarge ]: horizontal })} />
      </ButtonBase>

      <ButtonBase className={classes.button} onClick={addRect}>
        <IconSquare className={clsx(classes.icon, { [ classes.iconLarge ]: horizontal })} />
      </ButtonBase>

      <ButtonBase className={classes.button} onClick={addTriangle}>
        <IconTriangle className={clsx(classes.icon, { [ classes.iconLarge ]: horizontal })} />
      </ButtonBase>

      <ButtonBase className={classes.button} onClick={addHexagon}>
        <IconHexagon className={clsx(classes.icon, { [ classes.iconLarge ]: horizontal })} />
      </ButtonBase>


    </div>
  );
};

export default TabShapes;