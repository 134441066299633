import React, { useEffect, useState } from "react";
import clsx from 'clsx';

import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, CircularProgress, ListItem, IconButton, Popover, MenuItem, Tooltip } from "@material-ui/core";
import IconMoreVert from '@material-ui/icons/MoreVert';
import IconError from '@material-ui/icons/ErrorOutline';
import LocalFile from "../../LocalFile";
import { UserFontData } from "repix-common";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";

import { actions } from '../../../services/fonts';
import { useDialog } from "../../DialogProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTabFonts: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      },
      fontItem: {
        position: 'relative',
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(0),
      },
      space: {
        height: theme.spacing(1),
        width: theme.spacing(1),
      },
      action: {
        // position: 'absolute',
        // right: 0,
        marginRight: theme.spacing(0.5),
      },
      progress: {
        flex: '0 0 auto',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(0.5),
      },
      fontThumb: {
        height: 20,
      },
      errorName: {
        position: 'relative',
      },
      errorMessage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }
    }
  ),
);


const FontItem: React.FC<ListChildComponentProps> = ({ index, style, data: state }) => {
  const classes = useStyles();

  const font = state.userFonts[ index ] as UserFontData;

  const dialog = useDialog();
  const dispatch = useDispatch();

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  }

  const onDelete = () => {
    close();
    dialog.confirm({
      title: 'Are you sure, you want to delete this font?',
      onConfirm: () => {
        dispatch(actions.deleteFont(font));

      },
    })
  }


  if (font.status === 'pending') {
    const progress = state.uploadProgress[ font.id ];
    return (
      <div style={style}>
        <ListItem style={style} className={classes.fontItem} disableGutters>
          <CircularProgress variant={progress ? "static" : "indeterminate"} className={classes.progress} size={20} />
          <Typography variant="body1">{font.fontFamily || font.fileName}</Typography>
        </ListItem>
      </div>
    );
  }

  const fontMenu = (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MenuItem onClick={onDelete}>Delete</MenuItem>
    </Popover>
  )

  if (font.status === 'error') {
    return (
      <React.Fragment>
        <ListItem style={style} className={classes.fontItem} disableGutters>
          <IconButton size="small" className={classes.action} onClick={open}>
            <Tooltip title="Error processing the font">
              <IconError />
            </Tooltip>
          </IconButton>
          <Typography variant="body1" noWrap>{font.fontFamily || font.fileName}</Typography>
        </ListItem>
        {fontMenu}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ListItem style={style} className={classes.fontItem} disableGutters>
        <IconButton size="small" className={classes.action} onClick={open}>
          <IconMoreVert />
        </IconButton>
        <img src={`${process.env.CDN_URL}/${font.thumb.path}`} className={classes.fontThumb} alt={font.fontFamily} />
      </ListItem>
      {fontMenu}
    </React.Fragment>
  );
};

interface Props {
  listWidth: number;
  listHeight: number;
}

const TabFonts: React.FC<Props> = ({listWidth, listHeight}) => {
  const classes = useStyles();

  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const fonts = useSelector((state: RootState) => state.fonts);

  useEffect(() => {
    dispatch(actions.loadListLive());
  }, []);

  const onFontSelect = (file: File) => {
    setError('');

    const arr = file.name.split('.');
    const ext = arr[arr.length - 1].toLowerCase();
    if (ext !== 'woff' && ext !== 'ttf') {
      setError('Currently we support only WOFF and TTF fonts');
      return;
    }
    dispatch(actions.uploadFont(file));
  }



  return (
    <div className={classes.rootTabFonts}>
      <LocalFile label="Upload a font" onChange={onFontSelect} fileType="font/*" />
      {
        error ? <Typography color="error" align="center" variant="caption">{error}</Typography> : null
      }
      <div className={classes.space} />
      <div className={classes.space} />
      <List
        height={listHeight}
        itemCount={fonts.userFonts.length}
        itemSize={50}
        width={listWidth}
        itemData={fonts}
        style={{ overflowX: 'hidden' }}
      >
        {FontItem}
      </List>
    </div>
  );
};

export default React.memo(TabFonts);