import React, { useState, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Tab, Paper, Tabs } from "@material-ui/core";

import orderBy from 'lodash/orderBy';

import { actions } from '../../../services/devices';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";

import clsx from 'clsx';

import DeviceImg from '../../DeviceImg';
import { Device } from "repix-common";
import { loadDevice } from "../../../utils/fabricHelper";
import { useCanvas } from "../CanvasProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTabDevices: {
        display: 'flex',
        flexDirection: 'column',
      },
      tab: {
        minWidth: 20,
      },
      icon: {
        width: 20,
      },
      deviceList: {
        padding: theme.spacing(2, 0),
        overflow: 'scroll',
        maxHeight: 'calc(100vh - 128px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      deviceListHorizontal: {
        flexDirection: 'row',
        maxHeight: 'auto',
        padding: theme.spacing(2, 2),
      },
      deviceItem: {
        marginBottom: theme.spacing(1),
      },
      deviceItemHor: {
        marginBottom: 0,
        marginRight: theme.spacing(1),
      },
    }
  ),
);

interface Props {
  horizontal?: boolean;
  onAdd?: () => void;
  activeCanvas: fabric.Canvas;
}

const TabDevices: React.FC<Props> = ({ horizontal, onAdd, activeCanvas }) => {
  const classes = useStyles();
  const [ tab, setTab ] = useState(0);
  const { defaultFont } = useCanvas();

  const dispatch = useDispatch();
  const devices = useSelector((state: RootState) => state.devices);

  useEffect(() => {
    dispatch(actions.loadList());
  }, []);

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const onDeviceClick = async (device: Device) => {
    if (!activeCanvas) {
      return;
    }

    onAdd && onAdd();

    const canvasDevice = await loadDevice({
      device,
      orientation: 'portrait',
      defaultFont: defaultFont,
    });

    canvasDevice.scaleToWidth(activeCanvas.getScaledWidth() - 100);
    if (canvasDevice.getScaledHeight() >= activeCanvas.getScaledHeight() * 0.75) {
      canvasDevice.scaleToHeight(activeCanvas.getScaledHeight() * 0.75);
    }
    canvasDevice.set({
      lockUniScaling: true,
      centeredScaling: true,
      originX: 'center',
      originY: 'center',
    });

    activeCanvas?.add(canvasDevice);
    canvasDevice.viewportCenter();
    canvasDevice.setCoords();
    activeCanvas?.setActiveObject(canvasDevice);
    activeCanvas?.updated();
  }

  const deviceList = orderBy(devices.devices, "sort", "asc").filter(device => device.platform === (tab === 0 ? 'ios' : 'android'));

  return (
    <div className={classes.rootTabDevices}>
      <Tabs value={tab} onChange={onTabChange} variant="fullWidth">
        <Tab label="Apple" className={classes.tab} />
        <Tab label="Android" className={classes.tab} />
      </Tabs>

      <div className={clsx(classes.deviceList, { [ classes.deviceListHorizontal ]: horizontal })}>
        {
          deviceList.map(device => (
            <DeviceImg
              key={device.id}
              device={device}
              size={horizontal ? 120 : 140}
              onClick={onDeviceClick}
              className={clsx(classes.deviceItem, { [ classes.deviceItemHor ]: horizontal })}
              showName
            />
          ))
        }
      </div>

    </div>
  );
};

export default React.memo(TabDevices);