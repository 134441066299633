import React, { useEffect, useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button, Tooltip, IconButton, Select, MenuItem, FormControl, InputLabel, Typography, Divider } from "@material-ui/core";

import IconPortrait from '@material-ui/icons/StayCurrentPortrait';
import IconLandscape from '@material-ui/icons/StayCurrentLandscape';

import { actions, loadDevice as loadDeviceData } from '../../../services/devices';
import { useDispatch, useSelector } from "react-redux";
import { useCanvas } from "../CanvasProvider";
import { RootState } from "../../../store";

import orderBy from 'lodash/orderBy';
import { loadDevice } from "../../../utils/fabricHelper";

import ImagePickerDialog from '../../ImagePickerDialog/ImagePickerDialog';
import { UserImage, StockPhoto, Device } from "repix-common";
import { fabric } from "fabric";
import { CanvasOrientation } from "../../../fabric";
import CommonProperties from "./properties/CommonProperties";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootDeviceProperties: {
        display: 'flex',
        alignItems: 'center',
      },
      colorIndicator: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        marginRight: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
      },
      colorButton: {
        minWidth: 120,
        padding: theme.spacing(1, 2),
      },
      screenshotBtn: {
        flex: '0 0 auto',
        height: 40,
      },
      spacer: {
        width: theme.spacing(1),
      },
      styleSelect: {
        minWidth: 150,
        flex: '0 0 auto',
      },
      styleMenuItem: {
        display: 'flex',
        alignItems: 'center',
      }
    }
  ),
);

const DeviceProperties: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedObjects, activeCanvas, defaultFont } = useCanvas();

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const deviceObject = selectedObjects && selectedObjects[ 0 ] as fabric.Group;

  const devices = useSelector((state: RootState) => state.devices.devices);
  const deviceId = deviceObject && deviceObject.data.id;
  const color = deviceObject && deviceObject.data.color;

  const deviceData = deviceObject && devices[ deviceObject.data.id ];

  const _loadDeviceData = async () => {
    if (deviceData || !deviceId) {
      return;
    }
    const device = await loadDeviceData(deviceId);
    if (device) {
      dispatch(actions.setDevice(device));
    }
  }

  useEffect(() => {
    _loadDeviceData();
  }, []);


  if (!deviceData) {
    return null;
  }

  const openDialog = () => {
    setDialogOpen(true);
  }
  const closeDialog = () => {
    setDialogOpen(false);
  }

  const updateDevice = async (args: { device: Device, color?: string, screenshot?: string, orientation?: CanvasOrientation }) => {

    if (!deviceObject || !activeCanvas) {
      return;
    }



    const newDevice = await loadDevice({
      device: deviceData,
      color: args.color,
      screenshot: args.screenshot,
      orientation: args.orientation,
      defaultFont,
    })

    deviceObject.data = {
      id: deviceId,
    }

    newDevice.set({
      scaleX: deviceObject.scaleX,
      scaleY: deviceObject.scaleY,
      top: deviceObject.top,
      left: deviceObject.left,
      originX: deviceObject.originX,
      originY: deviceObject.originY,
      angle: deviceObject.angle,
    });

    const objects = activeCanvas.getObjects();
    const index = objects.indexOf(deviceObject);

    activeCanvas.remove(deviceObject);
    activeCanvas.add(newDevice);

    if (index !== -1) {
      activeCanvas.moveTo(newDevice, index);
    }

    activeCanvas.setActiveObject(newDevice);
    activeCanvas?.updated();
  }

  const onColorChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!deviceObject) {
      return;
    }

    updateDevice({
      device: deviceData,
      color: event.target.value as string,
      screenshot: deviceObject.data.screenshot,
      orientation: deviceObject.data.orientation,
    })
  };

  const onFileSelect = (selected: { image?: UserImage, stockPhoto?: StockPhoto }) => {
    if (!deviceObject) {
      return;
    }

    let imgUrl: string;
    if (selected.image) {
      imgUrl = `${process.env.CDN_URL}/${selected.image?.path}`;
    }
    else {
      imgUrl = `${process.env.CDN_URL}/${selected.stockPhoto?.large.path}`;
    }

    console.log('onFileSelect', imgUrl);

    updateDevice({
      device: deviceData,
      color: deviceObject.data.color,
      screenshot: imgUrl,
      orientation: deviceObject.data.orientation,
    });

    closeDialog();
  }

  const orientation = deviceObject?.data.orientation as CanvasOrientation;

  const toggleOrientation = () => {
    updateDevice({
      device: deviceData,
      color: deviceObject?.data.color,
      screenshot: deviceObject?.data.screenshot,
      orientation: orientation === 'portrait' ? 'landscape' : 'portrait',
    });
  }


  const colors = orderBy(deviceData.images, 'colorName', 'asc');


  return (
    <div className={classes.rootDeviceProperties}>
      <CommonProperties />
      <Tooltip title={orientation === 'landscape' ? 'Portrait Device' : 'Landscape Device'}>
        <IconButton onClick={toggleOrientation}>
          {
            orientation === 'landscape' ? <IconPortrait /> : <IconLandscape />
          }
        </IconButton>
      </Tooltip>
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <Button variant="outlined" className={classes.screenshotBtn} onClick={openDialog}>
        Upload Screenshot...
      </Button>

      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />

      <Divider flexItem orientation="vertical" />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />

      <Typography variant="caption">Style/Color</Typography>
      <div className={classes.spacer} />

      <FormControl variant="outlined" size="small" className={classes.styleSelect}>

        <Select
          labelId="device-style"
          value={color}
          onChange={onColorChange}
        >
          {
            colors.map(deviceColor => (
              <MenuItem key={deviceColor.id} value={deviceColor.id}>
                <div className={classes.styleMenuItem}>
                  <div className={classes.colorIndicator} style={{ backgroundColor: deviceColor.color }} />
                  {deviceColor.name}
                </div>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>


      {/* <ToggleButtonGroup
        value={color}
        exclusive
        onChange={onColorChange}
      >
        {
          colors.map(deviceColor => (
            <ToggleButton key={deviceColor.colorHex} value={deviceColor.id} size="small" className={classes.colorButton}>
              <div className={classes.colorIndicator} style={{ backgroundColor: deviceColor.colorHex }} />
              {deviceColor.colorName}
            </ToggleButton>
          ))
        }

      </ToggleButtonGroup> */}
      <ImagePickerDialog open={dialogOpen} onCancel={closeDialog} onSelect={onFileSelect} imageType="screenshot" stockPhotosCategory="background" />
    </div>
  );
};

export default DeviceProperties;