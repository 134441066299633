import React, { useEffect } from "react";
import { useCanvas } from "./CanvasProvider";



const ObjectSnapping: React.FC = () => {
  const { activeCanvas } = useCanvas();

  const getTop = (object: fabric.Object) => {
    if (!object.height || !object.top) {
      return 0;
    }

    if (object.originY === 'center') {
      return object.top;
    }
    else {
      return object.top + (object.getScaledHeight() / 2);
    }
  }
  const getLeft = (object: fabric.Object) => {
    if (!object.width || !object.left) {
      return 0;
    }

    if (object.originX === 'center') {
      return object.left;
    }
    else {
      return object.left + (object.getScaledWidth() / 2);
    }
  }


  useEffect(() => {
    if (!activeCanvas) {
      return;
    }

    let snapVVisible = false;
    let snapHVisible = false;

    const getVSnapLine = () => {
      return new fabric.Line([
        activeCanvas.getScaledWidth() / 2,
        0,
        activeCanvas.getScaledWidth() / 2,
        activeCanvas.getScaledHeight(),
      ], {
        stroke: 'blue',
        name: 'v_snap_line',
        strokeDashArray: [ 5, 5 ],
      });
    }

    const getHSnapLine = () => {
      return new fabric.Line([
        0,
        activeCanvas.getScaledHeight() / 2,
        activeCanvas.getScaledWidth(),
        activeCanvas.getScaledHeight() / 2,
      ], {
        name: 'h_snap_line',
        stroke: 'blue',
        strokeDashArray: [ 5, 5 ],
      });
    }

    const removeSnapLines = (names: string[]) => {
      const objects = activeCanvas.getObjects();
      for (const obj of objects) {
        if (obj.name && names.includes(obj.name)) {
          activeCanvas.remove(obj);
        }
      }
    }

    const onMoving = (e: fabric.IEvent) => {
      if (!e.target) {
        return;
      }

      const centerH = activeCanvas.getScaledWidth() / 2;
      const centerV = activeCanvas.getScaledHeight() / 2;
      const top = getTop(e.target);
      const left = getLeft(e.target);
      const lineV = getVSnapLine();
      const lineH = getHSnapLine();

      if (Math.abs(left - centerH) <= 25) {
        e.target.viewportCenterH().setCoords();
        if (!snapHVisible) {
          snapHVisible = true;
          activeCanvas.add(lineV);
          lineV.bringToFront();
          activeCanvas.renderAll();
        }
      }
      else {
        if (snapHVisible) {
          snapHVisible = false;
          removeSnapLines([ 'v_snap_line' ]);
        }
      }


      if (Math.abs(top - centerV) <= 25) {
        e.target.viewportCenterV().setCoords();
        if (!snapVVisible) {
          snapVVisible = true;
          activeCanvas.add(lineH);
          lineH.bringToFront();
          activeCanvas.renderAll();
        }
      }
      else {
        if (snapVVisible) {
          snapVVisible = false;
          removeSnapLines([ 'h_snap_line' ]);
        }
      }
    }

    const onMoved = () => {
      removeSnapLines([ 'v_snap_line', 'h_snap_line' ]);
      snapVVisible = false;
      snapHVisible = false;
    }


    activeCanvas.on('object:moving', onMoving);
    activeCanvas.on('object:moved', onMoved);

    return () => {
      activeCanvas.off('object:moving', onMoving);
      activeCanvas.off('object:moved', onMoved);
    }

  }, [ activeCanvas ]);

  return null;
};

export default ObjectSnapping;