import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Toolbar, IconButton, Paper, Tooltip, Button, Divider } from "@material-ui/core";

import IconUndo from '@material-ui/icons/Undo';
import IconRedo from '@material-ui/icons/Redo';
import IconAdd from '@material-ui/icons/LibraryAdd';
import IconDelete from '@material-ui/icons/Delete';
import { useCanvas } from "./CanvasProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      toolbar: {
        // margin: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        
      },
      spacer: {
        width: theme.spacing(1),
      },
      divider: {
        margin: theme.spacing(1, 0),
      }
    }
  ),
);

export const CanvasToolbar: React.FC = () => {
  const classes = useStyles();

  const {
    activeCanvas: canvas,
    removeCanvas,
    activeIndex,
    duplicateCanvas } = useCanvas();

  const remove = () => {
    if (typeof activeIndex === 'undefined') {
      return;
    }

    removeCanvas(activeIndex);
  }

  const clone = () => {
    if (typeof activeIndex === 'undefined') {
      return;
    }

    duplicateCanvas(activeIndex);
  }


  return (
    <Toolbar className={classes.toolbar}>
      <Tooltip title="Undo">
        <IconButton disabled={!Boolean(canvas)}>
          <IconUndo />
        </IconButton>
      </Tooltip>

      <Tooltip title="Redo">
        <IconButton disabled={!Boolean(canvas)}>
          <IconRedo />
        </IconButton>
      </Tooltip>

      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <Divider orientation="vertical" flexItem light variant="middle" className={classes.divider} />
      <div className={classes.spacer} />

      <Tooltip title="Duplicate">
        <IconButton disabled={!Boolean(canvas)} onClick={clone}>
          <IconAdd />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete">
        <IconButton disabled={!Boolean(canvas)} onClick={remove}>
          <IconDelete />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};
