import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import DeleteButton from './DeleteButton';
import ArrangeOrder from './ArrangeOrder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootCommonProperties: {
      },
    }
  ),
);

const CommonProperties: React.FC = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <DeleteButton />
      <ArrangeOrder />
    </React.Fragment>
  );
};

export default CommonProperties;