import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  IconButton,
  Tooltip,
  Popover,
  Paper,
} from "@material-ui/core";
import IconGradient from '@material-ui/icons/GradientOutlined';
import { ChromePicker } from 'react-color';
import { fabric } from "fabric";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

import IconArrowDown from '@material-ui/icons/ArrowDownward';
import IconArrowUp from '@material-ui/icons/ArrowUpward';
import IconArrowLeft from '@material-ui/icons/ArrowForward';
import IconArrowRight from '@material-ui/icons/ArrowBack';
import { useCanvas } from "../../CanvasProvider";
import { coordsToDirection, directionToCoords } from "../../../../utils/fabricHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootGradientPicker: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
      },
      selectDirection: {
        minWidth: 120,
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      colors: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }
    }
  ),
);

type GradientDirection = 'top_to_bottom' | 'bottom_to_top' | 'left_to_right' | 'right_to_left';

interface Props {
  value?: fabric.Gradient;
  onChange: (value: fabric.Gradient) => void;
  title: string;
}

interface CreateGradientArgs {
  color1?: string;
  color2?: string;
  direction?: GradientDirection;
}

const GradientPicker: React.FC<Props> = ({ value, onChange, title }) => {
  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const { activeCanvas } = useCanvas();

  if (!activeCanvas) {
    return null;
  }

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  }

  


  const pickerStyles = {
    default: {
      picker: {
        'boxShadow': 'nonw',
        border: '1px solid #eee',
      },
    }
  };

  const color1 = value && value.colorStops ? value.colorStops[ 0 ].color : 'rgba(255, 255, 255, 0)';
  const color2 = value && value.colorStops ? value.colorStops[ 1 ].color : 'rgba(255, 255, 255, 0)';
  const direction = coordsToDirection(value?.coords);

  const createGradient = (args: CreateGradientArgs) => {
    const color1 = args.color1 || (value && value.colorStops ? value.colorStops[ 0 ].color : 'rgba(255, 255, 255, 0)');
    const color2 = args.color2 || (value && value.colorStops ? value.colorStops[ 1 ].color : 'rgba(255, 255, 255, 0)');
    const coords = directionToCoords(args.direction || direction, activeCanvas);

    const gradient = new fabric.Gradient({
      type: 'linear',
      coords,
      colorStops: [
        { color: color1, offset: 0 },
        { color: color2, offset: 1 },
      ] as any
    })
    onChange(gradient);
  }

  const onColor1Change = (color: string) => {
    createGradient({ color1: color });
  }
  const onColor2Change = (color: string) => {
    createGradient({ color2: color });
  }

  const onDirectionChange = (event: React.MouseEvent<HTMLElement>, newDirection: string | null) => {
    createGradient({ direction: newDirection as GradientDirection });
  };

  return (
    <React.Fragment>
      <Tooltip title={title}>
        <IconButton onClick={open}>
          <IconGradient />
        </IconButton>
      </Tooltip>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper className={classes.rootGradientPicker}>
          <ToggleButtonGroup
            value={direction}
            onChange={onDirectionChange}
            exclusive
          >
            <ToggleButton value="top_to_bottom" size="small">
              <Tooltip title="Top to Bottom">
                <IconArrowDown />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="bottom_to_top" size="small">
              <Tooltip title="Bottom to Top">
                <IconArrowUp />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="left_to_right" size="small">
              <Tooltip title="Left to Right">
                <IconArrowRight />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="right_to_left" size="small">
              <Tooltip title="Right to Left">
                <IconArrowLeft />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          {/* <FormControl className={classes.selectDirection} variant="outlined">
            <InputLabel id="direction-label">Direction</InputLabel>
            <Select
              label="Direction"
              // value="top_to_bottom"
              labelId="direction-label"
            >
              <MenuItem value="top_to_bottom">Top to Bottom</MenuItem>
              <MenuItem value="bottom_to_top">Bottom to Top</MenuItem>
              <MenuItem value="left_to_right">Left to Right</MenuItem>
              <MenuItem value="right_to_left">Right to Left</MenuItem>
            </Select>
          </FormControl> */}

          <div className={classes.colors}>
            <ChromePicker styles={pickerStyles} color={color1} onChange={(color) => { onColor1Change(color.hex) }} />
            <div className={classes.spacer} />
            <div className={classes.spacer} />
            <ChromePicker styles={pickerStyles} color={color2} onChange={(color) => { onColor2Change(color.hex) }} />
          </div>

        </Paper>
      </Popover>
    </React.Fragment>

  );
};

export default GradientPicker;