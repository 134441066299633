import React, { Component, ReactElement, ReactComponentElement, FunctionComponent } from "react"
import { navigate } from "gatsby"
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { RouteComponentProps } from "@reach/router";
import Logo from "../../components/Logo";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }
  ),
);

interface PrivateRouteProps extends RouteComponentProps {
  component: any;
  admin?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, admin, location, ...rest }) => {
  const classes = useStyles();

  const user = useSelector((state: RootState) => state.user);

  if (user.userStateLoading || !location) {
    return (
      <div className={classes.root}>
        <Logo variant={admin ? 'admin' : "full"} size={200} />
      </div>
    );
  }

  if (user.user === null && location.pathname !== '/app/sign-in') {
    typeof window !== 'undefined' && navigate(`/user/sign-in?next=${encodeURI(location.pathname)}`, { replace: true });
    return null;
  }

  if (admin && !user.user?.roles.includes('admin')) {
    return null;
  }

  return <Component {...rest} />

};

export default PrivateRoute;