import React from "react";
import { fabric } from 'fabric';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button } from "@material-ui/core";
import { useCanvas } from "../CanvasProvider";



import IconAdd from '@material-ui/icons/Add'
import { FontDataLight } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      tabText: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
      },
    }
  ),
);

let defaultAlreadyLoaded = false;

interface Props {
  onAdd?: () => void;
}

const TabText: React.FC<Props> = ({onAdd}) => {
  const classes = useStyles();
  const { activeCanvas: canvas, defaultFont, loadFonts } = useCanvas();

  const loadDefaultFont = async () => {
    if (defaultAlreadyLoaded || !defaultFont) {
      return;
    }

    defaultAlreadyLoaded = true;

    await loadFonts([ {
      fontFamily: defaultFont.fontFamily,
      id: defaultFont.id,
      type: defaultFont.type,
      css: defaultFont.css,
    } ])
  }

  const addText = async () => {

    onAdd && onAdd();
    
    const options: fabric.ITextboxOptions = {
      fontSize: 80,
      textAlign: 'center',
      originX: 'left',
      originY: 'top',
      width: 500,
      charSpacing: 0.1,
    };
    
    if (defaultFont) {
      await loadDefaultFont();
      options.fontFamily = defaultFont.fontFamily;
      options.data = {
        fontId: defaultFont.id,
        css: defaultFont.css,
        type: defaultFont.type,
      }
    }

    const text = new fabric.Textbox("Hello World", options);

    text.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      ml: true,
      mr: true,
      mt: false,
      tl: false,
      tr: false,
      mtr: true,
    })

    canvas?.add(text);
    // canvas?.add(text1);
    canvas?.setActiveObject(text);
    canvas?.updated();
  }
  return (
    <div className={classes.tabText}>
      <Button onClick={addText} startIcon={<IconAdd />}>Add Text</Button>
    </div>
  );
};

export default TabText;