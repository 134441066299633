import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import Dialog from '../../Dialog';

import TemplateList from './TemplateList';
import { TemplateData, TemplateDesign } from "repix-common";
import { loadDesigns } from "../../../services/templates";
import { FontLoadInfo } from "../CanvasProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTemplatePickerDialog: {
      },
    }
  ),
);

export interface OnPickArgs {
  designs: TemplateDesign[];
  fonts: FontLoadInfo[];
  template: TemplateData;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onPick: (args: OnPickArgs) => void;
}

const TemplatePickerDialog: React.FC<Props> = ({ open, onClose, onPick }) => {
  const classes = useStyles();

  const [ selected, setSelected ] = useState<TemplateData>();
  const [ importLoading, setImportLoading ] = useState(false);

  const _onPick = async () => {
    if (!selected) {
      return;
    }

    const designs = await loadDesigns(selected.id, [ 'active' ]);

    onPick({
      ...designs,
      template: selected,
    })
  }


  const onSelect = (template: TemplateData) => {
    setSelected(template);
  }

  return (
    <Dialog
      title="Pick a design"
      open={open}
      cancelCallback={onClose}
      okCallback={_onPick}
      okLabel="Import"
      disabled={!Boolean(selected)}
      inProgress={importLoading}
    >
      <TemplateList onSelect={onSelect} selectedId={selected?.id} />
    </Dialog>
  );
};

export default TemplatePickerDialog;