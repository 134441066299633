import React, { useState } from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Tooltip, IconButton, Box, Typography, Popover, ListItemIcon, ListItem } from "@material-ui/core";
import IconMoreVert from '@material-ui/icons/MoreVert';

import IconUndo from '@material-ui/icons/Undo';
import IconRedo from '@material-ui/icons/Redo';
import IconClone from '@material-ui/icons/FileCopyOutlined';
import IconDelete from '@material-ui/icons/DeleteOutlined';
import { useCanvas } from "./CanvasProvider";
import IconRotate from '@material-ui/icons/Rotate90DegreesCcwOutlined';
import { useDialog } from "../DialogProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      canvasActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        transition: 'opacity 200ms',
        width: '100%',
        '@media (max-width:500px)': {
          marginBottom: 0,
        }
      },
      placeholder: {
        width: 1,
        height: 56,
      },

      hidden: {
        visibility: 'hidden',
      },
      rightSide: {
        '@media (max-width:500px)': {
          display: 'none',
        }
      },
      menuButton: {
        display: 'none',
        '@media (max-width:500px)': {
          display: 'inline-block',
        }
      },
      menuIcon: {
        minWidth: theme.spacing(5),
      },
      iconFlipped: {
        transform: 'scaleX(-1)',
      }

    }
  ),
);

interface Props {
  hidden?: boolean;
}

export const CanvasActions: React.FC<Props> = ({ hidden }) => {
  const classes = useStyles();

  const {
    activeCanvas: canvas,
    removeCanvas,
    activeIndex,
    duplicateCanvas,
    canvases
  } = useCanvas();

  const dialog = useDialog();
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  }

  if (!canvas || typeof activeIndex === 'undefined') {
    return <div className={classes.placeholder} />;
  }

  const remove = () => {
    if (typeof activeIndex === 'undefined') {
      return;
    }
    closeMenu();

    dialog.confirm({
      title: 'Are you sure, you want to delete?',
      onConfirm: () => {
        removeCanvas(activeIndex);
      }
    })

  }

  const clone = () => {
    if (typeof activeIndex === 'undefined') {
      return;
    }
    closeMenu();
    duplicateCanvas(activeIndex);
  }

  const undo = () => {
    canvas?.undo();
  }

  const redo = () => {
    canvas?.redo();
  }

  const orientation = canvas.getScaledWidth() > canvas.getScaledHeight() ? 'landscape' : 'portrait';

  const toggleOrientation = () => {
    closeMenu();
    canvas.setOrientation(orientation === 'landscape' ? 'portrait' : 'landscape');
  }

  const canUndo = canvas && canvas.undoIndex + 1 < canvas.history.length;
  const canRedo = canvas && canvas.undoIndex > 0;

  const canvasWidth = canvases[ activeIndex ].width;
  const canvasHeight = canvases[ activeIndex ].height;
  const sizeLabel = canvas.getOrientation() === 'portrait' ? `${canvasWidth}x${canvasHeight}` : `${canvasHeight}x${canvasWidth}`;


  return (
    <div className={clsx(classes.canvasActions, { [ classes.hidden ]: hidden })}>
      <Box>
        <IconButton disabled={!canUndo || hidden} onClick={undo}>
          <Tooltip title="Undo">
            <IconUndo />
          </Tooltip>
        </IconButton>

        <IconButton disabled={!canRedo || hidden} onClick={redo}>
          <Tooltip title="Redo">
            <IconRedo />
          </Tooltip>
        </IconButton>
      </Box>

      <Typography variant="caption">{sizeLabel}</Typography>

      <div className={classes.rightSide}>
        <IconButton disabled={!Boolean(canvas) || hidden} onClick={toggleOrientation}>
          <Tooltip title={orientation === 'landscape' ? 'Rotate -90°' : 'Rotate -90°'}>
            {
              orientation === 'landscape' ? <IconRotate className={classes.iconFlipped} /> : <IconRotate />
            }
          </Tooltip>
        </IconButton>

        <IconButton disabled={!Boolean(canvas) || hidden} onClick={clone}>
          <Tooltip title="Duplicate">
            <IconClone />
          </Tooltip>
        </IconButton>

        <IconButton disabled={!Boolean(canvas) || hidden} onClick={remove}>
          <Tooltip title="Delete">
            <IconDelete />
          </Tooltip>
        </IconButton>
      </div>

      <IconButton className={classes.menuButton} onClick={openMenu}>
        <IconMoreVert />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ListItem onClick={toggleOrientation} button>
          <ListItemIcon className={classes.menuIcon}>
            {
              orientation === 'landscape' ? <IconRotate className={classes.iconFlipped} /> : <IconRotate />
            }
          </ListItemIcon>
          {orientation === 'landscape' ? 'Rotate 90°' : 'Rotate -90°'}
        </ListItem>
        <ListItem onClick={clone} button>
          <ListItemIcon className={classes.menuIcon}>
            <IconClone />
          </ListItemIcon>
          Duplicate
        </ListItem>
        <ListItem onClick={remove} button>
          <ListItemIcon className={classes.menuIcon}>
            <IconDelete />
          </ListItemIcon>
          Delete
        </ListItem>
      </Popover>
    </div>
  );
};