import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import ColorPicker from "./properties/ColorPicker";
import { useCanvas } from "../CanvasProvider";
import CommonProperties from "./properties/CommonProperties";

import GroupColors from './properties/GroupColors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootElementProperties: {
        display: 'flex',
        alignItems: 'center',
      },
      space: {
        width: theme.spacing(1),
      }
    }
  ),
);

const ElementProperties: React.FC = () => {
  const classes = useStyles();
  const { activeCanvas, selectedObjects } = useCanvas();

  if (!selectedObjects || selectedObjects.length !== 1) {
    return null;
  }

  const svg = selectedObjects[ 0 ] as fabric.Object;

  const onColorChange = (color: string) => {
    if (svg.type === 'group') {
      (svg as fabric.Group).forEachObject((obj) => {
        obj.set({
          fill: color,
        });
      })
    }
    else if (svg.type === 'path') {
      console.log(JSON.stringify(svg));
      (svg as fabric.Path).set({
        fill: color,
      })
    }

    svg.data = {
      color
    };

    activeCanvas?.updated();
  }

  return (
    <div className={classes.rootElementProperties}>
      <CommonProperties />

      {/* <ColorPicker color={svg.data?.color as string} onChange={onColorChange} title="Fill Color" /> */}

      <GroupColors />

    </div>
  );
};

export default ElementProperties;