import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, CircularProgress, ButtonBase, IconButton, darken, Typography } from "@material-ui/core";
import { UserImage } from "repix-common";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
import { actions } from '../services/userImages';

import IconDelete from '@material-ui/icons/Close';
import { useDialog } from "./DialogProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootUserImg: {
        width: 250,
        height: 250,
        backgroundColor: darken(theme.palette.background.default, 0.05),
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(1),
        transition: 'all 200ms',
        border: `3px solid ${theme.palette.background.paper}`,
        position: 'relative',
        flex: '0 0 auto',
      },
      selected: {
        border: `3px solid ${theme.palette.secondary.main}`,
      },
      delete: {
        position: 'absolute',
        top: 5,
        left: 5,
        borderRadius: '50%',
        padding: theme.spacing(0.5),
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        color: '#ccc',
      },
      icon: {
        fontSize: 18,
      },
    progressCover: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      progressLabel: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
      },
      processingLabel: {
        marginTop: theme.spacing(1),
        color: '#fff',
      }
    }
  ),
);

interface Props {
  image: UserImage;
  className?: string;
  selected?: boolean;
  onClick?: (image: UserImage) => void;
  style?: any;
}
const UserImg: React.FC<Props> = ({ image, className, onClick, selected, style }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = useDialog();

  const imgUrl = `${process.env.CDN_URL}/${image.path}`;

  const userImages = useSelector((state: RootState) => state.userImages);

  const { localCache, uploadProgress } = userImages;

  const src = localCache[ image.id ] || imgUrl;

  const styles: any = {
    backgroundImage: `url(${src})`
  }

  if (onClick && image.status === 'active') {
    styles.cursor = 'pointer';
  }


  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    dialog.confirm({
      title: 'Are you sure, you want to delete?',
      onConfirm: () => {
        dispatch(actions.deleteImage(image.id));
      }
    })
  }




  return (
    <div
      className={clsx(classes.rootUserImg, className, { [ classes.selected ]: selected })}
      style={{
        ...styles,
        ...style,
      }}
      onClick={() => {
        onClick && image.status === 'active' && onClick(image);
      }}
    >
      {
        image.status === 'pending' ? (
          <div className={classes.progressCover}>
            <CircularProgress
              variant={uploadProgress[ image.id ] ? 'static' : 'indeterminate'}
              value={uploadProgress[ image.id ]}
              size={65}
              thickness={2.5}
            />
            {
              uploadProgress[ image.id ] ? (
                <Typography variant="caption" className={classes.progressLabel}>
                  {Math.round(uploadProgress[ image.id ])}%
                </Typography>
              ) : null
            }

            {
              typeof uploadProgress[ image.id ] === 'undefined' ? (
                <Typography variant="caption" className={classes.processingLabel}>
                  Processing...
                </Typography>
              ) : null
            }

          </div>
        ) : null
      }

      <ButtonBase className={classes.delete} onClick={onDelete}>
        <IconDelete className={classes.icon} />
      </ButtonBase>
    </div>
  );
};

export default React.memo(UserImg);