import React, { useEffect, useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, TextField, Typography, Link, Button, IconButton } from "@material-ui/core";

import IconBack from '@material-ui/icons/ArrowBack';

import {
  FixedSizeList as List,
  ListChildComponentProps,
  GridChildComponentProps,
  FixedSizeGrid as Grid,
  areEqual,
} from 'react-window';


import { actions } from '../../../services/designElements';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { loadSvgStr } from "../../../utils/fabricHelper";
import { Autocomplete } from "@material-ui/lab";
import { DesignElementCategory, DesignElement } from "repix-common";
import { fabric } from "fabric";
import memoizeOne from "memoize-one";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTabElements: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      space: {
        height: theme.spacing(1),
      },
      item: {
        paddingBottom: theme.spacing(2),
        textAlign: 'center',
      },
      categoryItem: {
        paddingBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
      },
      img: {
        width: 60,
        height: 60,
        cursor: 'pointer',
        paddingLeft: theme.spacing(2),
      },
      catItemHeader: {
        padding: theme.spacing(2, 2, 0, 2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      rightGradient: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 60,
        height: '100%',
        backgroundImage: 'linear-gradient(to left, white , transparent)',
        pointerEvents: 'none',
      },
      catList: {
        position: 'relative',
      },
      selectedCategory: {
        padding: theme.spacing(1),
      }
    }
  ),
);

const width = 230;


const ElemListItem: React.FC<ListChildComponentProps> = React.memo(({ index, style, data }) => {
  const classes = useStyles();
  const list = data.list as DesignElement[];
  const onClick = data.onClick;
  const element = list[ index ];
  return (
    <div style={style} className={classes.item}>
      <img key={element.id} src={`${process.env.CDN_URL}/${element.asset.path}`} className={classes.img} onClick={() => { onClick(element) }} />
    </div>
  );
}, areEqual);

const ElemGridItem: React.FC<GridChildComponentProps> = React.memo(({ columnIndex, rowIndex, style, data }) => {
  const classes = useStyles();
  const list = data.list as DesignElement[];
  const onClick = data.onClick;
  const index = rowIndex * 3 + columnIndex;

  if (index >= list.length) {
    return null;
  }

  const element = list[ index ];

  return (
    <div style={style} className={classes.item}>
      <img key={element.id} src={`${process.env.CDN_URL}/${element.asset.path}`} className={classes.img} onClick={() => { onClick(element) }} />
    </div>
  );
}, areEqual);


const ItemCategory: React.FC<ListChildComponentProps> = React.memo(({ index, style, data }) => {
  const classes = useStyles();
  const list = data.list as DesignElementCategory[];
  const onClick = data.onElemClick;
  const onCatClick = data.onCategoryClick;
  const category = list[ index ];

  return (
    <div style={style} className={classes.categoryItem}>
      <div className={classes.catItemHeader}>
        <Typography variant="body2" color="textPrimary">{category.name}</Typography>
        <Button size="small" color="inherit" onClick={() => {
          onCatClick(category);
        }}>See All</Button>
      </div>
      <div className={classes.catList}>
        <List
          layout="horizontal"
          height={60}
          width={width}
          itemCount={category.items.length}
          itemSize={60}
          itemData={createElemItemData(category.items, onClick)}
        >
          {ElemListItem}
        </List>
        <div className={classes.rightGradient} />
      </div>
    </div>
  );
}, areEqual);


const createElemItemData = memoizeOne((list, onClick) => ({
  list,
  onClick,
}))

const createCategoryItemData = memoizeOne((list, onElemClick, onCategoryClick) => ({
  list,
  onElemClick,
  onCategoryClick,
}))


interface Props {
  onAdd?: () => void;
  activeCanvas: fabric.Canvas;
}

const TabElements: React.FC<Props> = ({ onAdd, activeCanvas }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ category, setCategory ] = useState<DesignElementCategory | null>(null);

  const svgElements = useSelector((state: RootState) => state.designElements);

  useEffect(() => {
    dispatch(actions.loadCategories());
  }, [])

  useEffect(() => {
    if (!category) {
      return;
    }

    dispatch(actions.loadList(category.id));
  }, [ category ]);

  const onClick = async (element: DesignElement) => {
    onAdd && onAdd();
    // const img = await loadSvg(`${process.env.CDN_URL}/${element.asset.path}`);
    const img = await loadSvgStr(`${process.env.CDN_URL}/${element.asset.path}`);


    img.scaleToWidth(200);
    img.name = 'design_element';
    img.data = {
      elementId: element.id,
      type: element.type,
    }

    activeCanvas?.add(img);
    activeCanvas?.updated();
  }

  const onCategoryClick = (category: DesignElementCategory) => {
    setCategory(category);
  }

  const categoryData = createCategoryItemData(svgElements.categories, onClick, onCategoryClick);
  const list = category && svgElements.list[ category.id ] ? svgElements.list[ category.id ] : [];

  return (
    <div className={classes.rootTabElements}>
      {
        !category ? (
          <List
            height={window ? window.innerHeight - 70 : 0}
            itemCount={svgElements.categories.length}
            itemSize={120}
            width={width}
            itemData={categoryData}
          >
            {ItemCategory}
          </List>
        ) : (
            <div className={classes.selectedCategory}>
              <Autocomplete
                options={svgElements.categories}
                getOptionLabel={(option) => option.name}
                style={{ width: 200 }}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                onChange={(e, newValue) => { setCategory(newValue) }}
                value={category}
              />
              <Grid
                rowCount={Math.ceil(list.length / 3)}
                columnCount={3}
                rowHeight={60}
                columnWidth={width / 3.5}
                width={width - 20}
                height={window.innerHeight - 120}
                itemData={createElemItemData(list, onClick)}
              >
                {ElemGridItem}
              </Grid>
            </div>
          )
      }
    </div>
  );
};

export default React.memo(TabElements);