import React, { useEffect, useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, CircularProgress, Tabs, Tab, Paper, TextField } from "@material-ui/core";

import Dialog from '../Dialog';
import { UserImage, StockPhoto, UserImageType, StockPhotoCategory } from "repix-common";
import UploadImageButton from "./UploadImageButton";

import { actions as userImageActions } from '../../services/userImages';
import { actions as stockPhotoActions } from '../../services/stockPhotos';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

import UserImg from '../UserImg';
import StockPhotoImg from '../StockPhotoImg';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootDialogUserImages: {
      },
      imageList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        // borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(2),
        height: 700,
        overflow: 'auto',
      },
      img: {
        margin: theme.spacing(1),
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      }
    }
  ),
);

interface Props {
  open: boolean;
  onSelect: (args: { image?: UserImage, stockPhoto?: StockPhoto }) => void;
  onCancel: () => void;
  imageType: UserImageType;
  stockPhotosCategory: string;
}
const DialogUserImages: React.FC<Props> = ({ open, onSelect, onCancel, imageType, stockPhotosCategory }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ selectedImage, setSelectedImage ] = useState<UserImage | StockPhoto>();

  const [ tab, setTab ] = useState(0);
  const [ category, setCategory ] = useState<StockPhotoCategory>();

  const userImages = useSelector((state: RootState) => state.userImages);
  const stockPhotos = useSelector((state: RootState) => state.stockPhotos);

  const onImgClick = (img: UserImage) => {
    setSelectedImage(img);
  }
  const onStockPhotoClick = (photo: StockPhoto) => {
    setSelectedImage(photo);
  }

  useEffect(() => {
    if (!open) {
      return;
    }

    dispatch(userImageActions.loadList());
  }, [ open ]);

  useEffect(() => {
    if (!open) {
      return;
    }

    if (typeof category === 'undefined' && stockPhotos.categories.length > 0) {
      const index = stockPhotos.categories.findIndex(cat => cat.id === stockPhotosCategory);
      setCategory(stockPhotos.categories[ index === -1 ? 0 : index ]);
    }
  }, [ stockPhotos.categories.length, open ])

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!category) {
      dispatch(stockPhotoActions.loadCategories());
      return;
    }

    dispatch(stockPhotoActions.loadList(category.id));
  }, [ category, open ]);


  // useEffect(() => {

  //   if (tab === 0) {
  //     dispatch(actions.loadList());
  //   }
  //   else if (tab === 1) {
  //     dispatch(stockPhotoActions.loadList());
  //   }
  // }, [ open, tab ]);

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };


  const onOk = () => {
    if (!selectedImage) {
      return;
    }

    if ((selectedImage as StockPhoto).original) {
      onSelect({ stockPhoto: selectedImage as StockPhoto });
    }
    else {
      onSelect({ image: selectedImage as UserImage });
    }
  }

  const renderUserImages = () => {
    return (
      <React.Fragment>
        <div className={classes.spacer} />
        <div className={classes.spacer} />
        <UploadImageButton imageType={imageType} />
        <div className={classes.imageList}>
          {
            userImages.listLoading ? <CircularProgress /> : null
          }
          {
            userImages.images.map(img => (
              <UserImg image={img} key={img.id} className={classes.img} onClick={onImgClick} selected={img.id === selectedImage?.id} />
            ))
          }
        </div>
      </React.Fragment>
    );
  }

  const renderStockPhotos = () => {
    if (!category) {
      return null;
    }

    const list = stockPhotos.list[category.id] ?  stockPhotos.list[category.id] : [];

    return (
      <React.Fragment>
        <div className={classes.spacer} />
        <div className={classes.spacer} />
        <Autocomplete
          options={stockPhotos.categories}
          getOptionLabel={(option) => option.name}
          style={{ width: 200 }}
          renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
          onChange={(e, newValue) => { setCategory(newValue) }}
          value={category}
          disableClearable
        />
        <div className={classes.imageList}>
          {
            stockPhotos.listLoading ? <CircularProgress /> : null
          }
          {
            list.map(photo => (
              <StockPhotoImg photo={photo} key={photo.id} className={classes.img} onClick={onStockPhotoClick} selected={photo.id === selectedImage?.id} />
            ))
          }
        </div>
      </React.Fragment>
    );
  }

  return (
    <Dialog open={open} cancelCallback={onCancel} disabled={!Boolean(selectedImage)} okCallback={onOk} okLabel="Select">
      <Paper variant="outlined">
        <Tabs value={tab} onChange={onTabChange}>˝
          <Tab label="My Images" />
          <Tab label="Stock Photos" />
        </Tabs>
      </Paper>

      {
        tab === 0 ? renderUserImages() : renderStockPhotos()
      }

    </Dialog>
  );
};

export default DialogUserImages;