import React, { useEffect, useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button, Popover, MenuItem, List, Divider, ListSubheader, Typography, ListItem, ListItemText, Collapse } from "@material-ui/core";
import IconDropdown from '@material-ui/icons/ArrowDropDown';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { canvasSizeTypes } from "./FilterToggles";
import { useCanvas, SizeFilter } from "./CanvasProvider";

import TemplatePickerDialog, { OnPickArgs } from './TemplatePicker/TemplatePickerDialog';
import uniqueId from "../../utils/uniqueId";
import { useDialog } from "../DialogProvider";
import { trackEvent } from "../../utils/analytics";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootImportFrom: {
      },
      nested: {
        paddingLeft: theme.spacing(4),
      },
    }
  ),
);

interface ImportOption {
  width: number;
  height: number;
  name: string;
  count: number;
}

interface Props {
  className?: string;
  listMode?: boolean;
  onCloseDrawer?: () => void;
}
export const ImportFrom: React.FC<Props> = ({ className, listMode, onCloseDrawer }) => {
  const classes = useStyles();

  const { canvases, sizeFilter, loadFonts, addCanvas } = useCanvas();

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const [ importOptions, setImportOptions ] = useState<ImportOption[]>([]);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const dialog = useDialog();

  const [ importExpanded, setImportExpanded ] = useState(false);

  const toggleImportExpanded = () => {
    setImportExpanded(!importExpanded);
  }

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  }

  useEffect(() => {
    if (canvases.length === 0) {
      return;
    }

    const newImportOptions: ImportOption[] = [];

    canvasSizeTypes.forEach(type => {
      for (const subtype in type.subtypes) {
        const sizeArr = [ type.subtypes[ subtype ].width, type.subtypes[ subtype ].height ];

        const count = canvases.filter(canvas => {
          return sizeArr.includes(canvas.width) && sizeArr.includes(canvas.height)
        }).length;


        if (count > 0 && !sizeArr.includes(sizeFilter.width) && !sizeArr.includes(sizeFilter.height)) {
          newImportOptions.push({
            width: type.subtypes[ subtype ].width,
            height: type.subtypes[ subtype ].height,
            name: `${type.name} - ${type.subtypes[ subtype ].name}`,
            count,
          })
        }
      }

      setImportOptions(newImportOptions);

    })

  }, [ canvases.length, sizeFilter ]);

  const openDialog = () => {
    setDialogOpen(true);
    close();
  }
  const closeDialog = () => {
    setDialogOpen(false);
  }

  const onPickTemplate = async (args: OnPickArgs) => {

    closeDialog();

    onCloseDrawer && onCloseDrawer();

    if (args.fonts.length > 0) {
      await loadFonts(args.fonts);
    }

    trackEvent('project_designs_import', { fromTemplate: true });

    for (const design of args.designs) {
      await addCanvas({
        id: uniqueId(),
        width: sizeFilter.width,
        height: sizeFilter.height,
        data: design.data,
        template: args.template.id,
        design: design.id,
        magicResize: {
          fromWidth: design.width,
          fromHeight: design.height,
        }
      })
    }
  }

  const getNamesBySize = (size: SizeFilter) => {
    for (const type in canvasSizeTypes) {
      for (const subtypeKey in canvasSizeTypes[ type ].subtypes) {
        const subtype = canvasSizeTypes[ type ].subtypes[ subtypeKey ];
        if ([ size.width, size.height ].includes(subtype.width) && [ size.width, size.height ].includes(subtype.height)) {
          return {
            type: canvasSizeTypes[ type ].name,
            subtype: subtype.name,
          }
        }
      }
    }

    return false;
  }

  const getCanvasesBySize = (size: SizeFilter) => {
    return canvases.filter(canvas => {
      return [ size.width, size.height ].includes(canvas.width) && [ size.width, size.height ].includes(canvas.height)
    })
  }


  const importDesigns = (size: SizeFilter) => {
    const nameFrom = getNamesBySize(size);

    if (!nameFrom) {
      return;
    }

    trackEvent('project_designs_import', { fromTemplate: false });

    close();
    onCloseDrawer && onCloseDrawer();

    dialog.confirm({
      title: `Do you want to import designs from ${nameFrom.type} - ${nameFrom.subtype}?`,
      subtitle: 'The import process will try to do magic resize, which will resize and reposition objects to fit the best.',
      onConfirm: async () => {
        const canvasesToImport = getCanvasesBySize(size);

        console.log('canvases to Import', canvasesToImport);

        for (const canvas of canvasesToImport) {
          if (!canvas.instance) {
            return;
          }

          const data = canvas.instance.serialize();

          await addCanvas({
            id: uniqueId(),
            width: sizeFilter.width,
            height: sizeFilter.height,
            data,
            magicResize: {
              fromWidth: size.width,
              fromHeight: size.height,
            }
          })
        }
      }
    })
  }

  if (listMode) {
    return (
      <React.Fragment>
        <ListItem button onClick={toggleImportExpanded}>
          <ListItemText primary="Import Designs" />
          {importExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={importExpanded} timeout="auto" unmountOnExit>
          <ListItem button className={classes.nested} onClick={openDialog}>
            <ListItemText primary="From Design Templates" />
          </ListItem>
          <ListSubheader className={classes.nested}>
            From this project
          </ListSubheader>
          {
            importOptions.length === 0 ? (
              <ListItem className={classes.nested}>
                <ListItemText secondary="You don't have any other designs in this project." />
              </ListItem>
            ) : null
          }
          {
            importOptions.map(item => (
              <ListItem className={classes.nested} button key={item.name} onClick={() => importDesigns({ width: item.width, height: item.height })}>
                {item.name} ({item.count})
              </ListItem>
            ))
          }
        </Collapse>
        <TemplatePickerDialog open={dialogOpen} onClose={closeDialog} onPick={onPickTemplate} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Button className={className} endIcon={<IconDropdown />} variant="outlined" onClick={open}>
        Import Designs
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >

        <List>
          <MenuItem onClick={openDialog}>From Design Templates</MenuItem>
          <Divider />
          <ListSubheader>From this project</ListSubheader>
          {
            importOptions.length === 0 ? (
              <ListItem>
                <ListItemText secondary="You don't have any other designs in this project." />


              </ListItem>
            ) : null
          }
          {
            importOptions.map(item => (
              <MenuItem key={item.name} onClick={() => importDesigns({ width: item.width, height: item.height })}>
                {item.name} ({item.count})
              </MenuItem>
            ))
          }
        </List>

      </Popover>

      <TemplatePickerDialog open={dialogOpen} onClose={closeDialog} onPick={onPickTemplate} />
    </React.Fragment>
  );
};