import React, { ReactNode, useEffect, useLayoutEffect } from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, CircularProgress } from "@material-ui/core";
import { useCanvas } from "./CanvasProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      canvas: {
        width: 0,
        height: 0,
      },
      canvasWrapper: {
        backgroundImage: `url('/bg_pattern1.png')`,
        position: 'relative',
      },
      canvasCover: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
      },
      canvasLoading: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }
  ),
);

interface Props {
  id?: string;
  className?: string;
  index: number;
  scrollToView?: boolean;
  clearSelection?: boolean;
  disabled?: boolean;
  visible: boolean;
}

export const Canvas: React.FC<Props> = ({
  id = 'canvas',
  className,
  index,
  scrollToView = true,
  clearSelection = true,
  disabled,
  visible,
}) => {
  const classes = useStyles();
  const { activeIndex, canvases } = useCanvas();

  useLayoutEffect(() => {
    if (activeIndex !== index && clearSelection) {
      canvases[ index ] && canvases[ index ].instance?.discardActiveObject();
      canvases[ index ] && canvases[ index ].instance?.renderAll();
    }
    else if (activeIndex === index && scrollToView) {
      canvases[ index ] && canvases[ index ].instance?.getElement().scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }

  }, [ index, activeIndex ])

  const currCanvas = canvases.find(canvas => canvas.id === id);
  const instanceCreated = Boolean(currCanvas?.instance);



  return (
    <React.Fragment>
      <div className={classes.canvasWrapper} style={{ display: visible ? 'block' : 'none' }}>
        <canvas className={clsx(classes.canvas, className)} id={id} />
        {
          disabled ? <CanvasCover index={index} /> : null
        }
        {
          !instanceCreated ? <div className={classes.canvasLoading}><CircularProgress /></div> : null
        }
      </div>
    </React.Fragment>
  );
};



interface CanvasesProps {
  onRender: (args: { id: string; index: number; isActive: boolean; visible: boolean; }) => ReactNode,
}


export const Canvases: React.FC<CanvasesProps> = ({ onRender }) => {

  const { canvases, activeIndex, sizeFilter } = useCanvas();

  const sizeArr = [ sizeFilter.width, sizeFilter.height ];

  return (
    <React.Fragment>
      {
        canvases
          // .filter(canvas => sizeArr.includes(canvas.width) && sizeArr.includes(canvas.height))
          .map((item, index) => (onRender({
            id: item.id,
            index,
            isActive: activeIndex === index,
            visible: sizeArr.includes(item.width) && sizeArr.includes(item.height),
          })))
      }
    </React.Fragment>
  )
};



interface CanvasCoverProps {
  index: number;
}
export const CanvasCover: React.FC<CanvasCoverProps> = ({ index }) => {
  const classes = useStyles();

  const { setActiveCanvas } = useCanvas();

  return (
    <div className={classes.canvasCover} onClick={() => setActiveCanvas(index)} />
  );
};