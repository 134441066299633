import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  useTheme,
  useMediaQuery,
  DialogActions,
  Button,
  Slide,
} from "@material-ui/core";

import { TransitionProps } from '@material-ui/core/transitions';

import CloseIcon from '@material-ui/icons/Close';

import ProgressButton from './ProgressButton';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      dialog: {
      },
      title: {
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
      },
      content: {
        // backgroundColor: theme.palette.background.default,
      },
      bottom: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
      },
    }
  ),
);

interface Props {
  title?: string;
  open: boolean;
  okLabel?: string;
  cancelLabel?: string;
  okCallback: () => void;
  cancelCallback: () => void;
  inProgress?: boolean;
  disabled?: boolean;
  dividers?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const ResponsiveDialog: React.FC<Props> = ({
  children,
  title,
  open,
  okLabel = 'Ok',
  cancelLabel = 'Cancel',
  okCallback,
  cancelCallback,
  inProgress,
  disabled,
  dividers,
  maxWidth,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={cancelCallback}
      className={classes.dialog}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen}
      TransitionComponent={Transition}
    >
      {
        title ? (
          <DialogTitle className={classes.title}>
            {title}
            <IconButton aria-label="close" className={classes.closeButton} onClick={cancelCallback}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        ) : null
      }
      <DialogContent dividers={dividers} className={classes.content}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelCallback}>{cancelLabel}</Button>
        <ProgressButton loading={Boolean(inProgress)} onClick={okCallback} disabled={disabled || inProgress}>{okLabel}</ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResponsiveDialog;