import React, { useState } from "react";
import color from 'color';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ButtonBase, Popper, Popover, IconButton, Tooltip } from "@material-ui/core";

import IconColor from '@material-ui/icons/ColorLensOutlined';

import { SketchPicker, RGBColor } from 'react-color';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootColorPicker: {
        width: 30,
        height: 30,
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.palette.divider}`,
      },
      colorBox: {
        width: 20,
        height: 20,
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.palette.divider}`,
      }
    }
  ),
);

interface Props {
  color: string;
  onChange: (color: string) => void;
  title: string;
  showColor?: boolean;
}

const ColorPicker: React.FC<Props> = ({ color: colorValue, onChange, title, showColor }) => {
  const classes = useStyles();

  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  }


  const rgbToString = (color: RGBColor) => {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a ? color.a : 1})`
  }

  const colorStr = colorValue ? color(colorValue).rgb().string() : colorValue;

  return (
    <React.Fragment>
      <Tooltip title={title}>
        <IconButton onClick={open}>
          {
            showColor ?
              <div className={classes.colorBox} style={{ backgroundColor: colorStr }}></div>
              :
              <IconColor />
          }
        </IconButton>
      </Tooltip>
      {/* <ButtonBase className={classes.rootColorPicker} style={style} onClick={open} /> */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker
          color={colorStr}
          onChangeComplete={(props) => {
            onChange(rgbToString(props.rgb));
          }}
        />
      </Popover>
    </React.Fragment>
  );
};

export default ColorPicker;