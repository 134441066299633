import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ButtonBase, Typography } from "@material-ui/core";
import { Device } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootDeviceImg: {
        position: 'relative',
        borderRadius: theme.spacing(1),
        display: 'block',
      },
      title: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }
    }
  ),
);


interface Props {
  device: Device;
  color?: string;
  size?: number;
  className?: string;
  onClick?: (device: Device) => void;
  showName?: boolean;
}

const DeviceImg: React.FC<Props> = ({ device, color, size = 200, className, onClick, showName }) => {
  const classes = useStyles();

  const img = color ? device.images[ color ] : device.images[ device.mainImage ];

  const width = size;
  let height;
  if (img && img.width && img.height) {
    height = width / (img.width / img.height);
  }

  const _onClick = () => {
    onClick && onClick(device);
  }

  return (
    <ButtonBase onClick={_onClick} className={clsx(className, classes.rootDeviceImg)} disabled={!Boolean(onClick)} style={{ width, height }} component="div">
      <img src={`${process.env.CDN_URL}/${img?.path}`} style={{ width, height }} />
      {
        showName ? <Typography align="center" className={classes.title} variant="body2">{device.name}</Typography> : null
      }
    </ButtonBase>
  )

};

export default DeviceImg;