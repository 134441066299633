import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import CommonProperties from "./properties/CommonProperties";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootSvgProperties: {
      },
    }
  ),
);

const SvgProperties: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.rootSvgProperties}>
      <CommonProperties />
    </div>
  );
};

export default SvgProperties;