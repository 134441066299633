import React, { useState, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Divider, Typography } from "@material-ui/core";
import LocalFile from "../LocalFile";

import { actions } from '../../services/userImages';
import { useDispatch } from "react-redux";
import { UserImageType } from "repix-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootUploadImageButton: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'flex-start',
        alignItems: 'flex-start',
      },
      spacer: {
        height: theme.spacing(1),
        width: theme.spacing(1),
      },
      selectFile: {
        marginBottom: theme.spacing(1),
      },
    }
  ),
);

interface Props {
  imageType: UserImageType;
}

const UploadImageButton: React.FC<Props> = ({ imageType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ error, setError ] = useState('');

  useEffect(() => {
    setError('');
  }, []);

  const onSelectFile = (file: File, src: string) => {
    if (file.size > 10 * 1024 * 1024) {
      setError('Max image size is 10mb');
      return;
    }

    dispatch(actions.uploadImage({ file, src, type: imageType }));
  }

  return (
    <div className={classes.rootUploadImageButton}>
      <LocalFile onChange={onSelectFile} label="Upload Image..." />
      <div className={classes.spacer} />
      <Typography color="error" variant="caption">{error}</Typography>
      <div className={classes.spacer} />
    </div>
  );
};

export default UploadImageButton;