import React, { useContext } from "react";
import { fabric } from 'fabric';

import { CanvasData, FontDataLight, CanvasThumb } from "repix-common";
import uniqueId from "../../utils/uniqueId";
import { createInstance, NewCanvasArgs } from "./fabric";
import DeviceScreenshotDrop from "./DeviceScreenshotDrop";
import ObjectSnapping from "./ObjectSnapping";
import { extractFonts, FontLoadInfo } from "../../utils/fabricHelper";

export interface CanvasItem extends NewCanvasArgs {
  instance?: fabric.Canvas;
}

export interface SizeFilter {
  width: number;
  height: number;
}

type CanvasFlag = 'line_drawing';

interface IContext {
  readonly canvases: CanvasItem[];
  readonly activeCanvas?: fabric.Canvas;
  readonly activeIndex?: number;
  readonly selectedObjects?: fabric.Object[];
  readonly sizeFilter: SizeFilter;
  addCanvas: (args: NewCanvasArgs) => Promise<void>;
  setActiveCanvas: (index: number) => void;
  removeCanvas: (index: number) => void;
  duplicateCanvas: (index: number) => void;
  setSizeFilter: (size: SizeFilter) => void;
  fonts: FontDataLight[]
  loadFonts: (fontsToLoad: FontLoadInfo[]) => Promise<void>;
  defaultFont?: FontDataLight;
  flags: CanvasFlag[];
  addFlag: (flag: CanvasFlag) => void;
  removeFlag: (flag: CanvasFlag) => void;
}


const Context = React.createContext<IContext>({
  canvases: [],
  sizeFilter: {
    width: 1242,
    height: 2688,
  },
  fonts: [],
  flags: [],
  loadFonts: () => new Promise(() => { }),
  addCanvas: () => new Promise(() => { }),
  setActiveCanvas: () => { },
  removeCanvas: () => { },
  duplicateCanvas: () => { },
  setSizeFilter: () => { },
  addFlag: () => { },
  removeFlag: () => { },
});

interface Props {
  onCanvasAdd?: (canvas: CanvasItem, thumb: CanvasThumb) => void;
  onCanvasUpdate?: (canvas: CanvasItem, thumb: CanvasThumb) => void;
  onCanvasDelete?: (id: string) => void;
  onFontsLoaded?: () => void;
  canvases?: CanvasItem[]
  fonts: FontDataLight[];
  defaultFont?: FontDataLight;
  sizeFilter?: SizeFilter;
  onSizeFilterChange?: (sizeFilter: SizeFilter) => void;
  availableHeight: number;
}

interface State {
  canvases: CanvasItem[];
  index?: number;
  selectedObjects?: fabric.Object[];
  sizeFilter: SizeFilter;
  fonts: FontDataLight[];
  flags: CanvasFlag[];
}


export class CanvasProvider extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const sizeFilter = props.sizeFilter || {
      width: 1242,
      height: 2688,
    };

    const state: State = {
      canvases: [],
      fonts: props.fonts,
      sizeFilter: sizeFilter,
      flags: [],
    }


    this.state = state;
  }

  public static getDerivedStateFromProps(props: Props, state: State) {
    if (props.fonts.length !== state.fonts.length) {
      return {
        ...state,
        fonts: props.fonts,
      }
    }

    return null;
  }

  public componentDidUpdate() {
    for (const canvas of this.state.canvases) {
      canvas.instance?.resize(this.props.availableHeight);
    }
  }

  public async componentDidMount() {
    if (!this.props.canvases) {
      return;
    }
    const fontsToLoad: FontLoadInfo[] = [];

    
    try {
      for (const canvas of this.props.canvases) {
        if (!canvas.data) {
          continue;
        }
        
        const data = JSON.parse(canvas.data);
        fontsToLoad.push(...extractFonts(data));
      }
    }
    catch (e) {
      console.error(e);
    }

    let activeIndex: number;

    const sizeArr = [ this.state.sizeFilter.width, this.state.sizeFilter.height ];

    this.props.canvases.map((item, index) => {
      if (sizeArr.includes(item.width) && sizeArr.includes(item.height)) {
        activeIndex = index;
      }
    })

    await this.loadFonts(fontsToLoad);
    this.props.onFontsLoaded && this.props.onFontsLoaded();


    this.setState({
      canvases: this.props.canvases,
    }, async () => {
      for (let i = 0; i < this.state.canvases.length; i++) {
        const { instance, ...args } = this.state.canvases[ i ];
        this.state.canvases[ i ].instance = await this.createInstance(args)
      }

      if (typeof activeIndex !== 'undefined') {
        this.setActiveCanvas(activeIndex);
      }

    })
  }

  private async createInstance(args: NewCanvasArgs): Promise<fabric.Canvas> {
    const instance = await createInstance(args);
    instance.preserveObjectStacking = true;

    instance.on('selection:cleared', (options) => {
      if (typeof this.state.index === 'undefined') {
        return;
      }
      this.setState({
        selectedObjects: this.state.canvases[ this.state.index ].instance?.getActiveObjects()
      })
    });

    instance.on('selection:created', (options) => {
      if (typeof this.state.index === 'undefined') {
        return;
      }
      this.setState({
        selectedObjects: this.state.canvases[ this.state.index ].instance?.getActiveObjects()
      })
    });

    instance.on('selection:updated', (options) => {
      if (typeof this.state.index === 'undefined') {
        return;
      }
      this.setState({
        selectedObjects: this.state.canvases[ this.state.index ].instance?.getActiveObjects()
      })
    });

    instance.on('object:modified', (options) => {
      instance.updated(false);
    })

    instance.on('react-update', (options) => {
      this.forceUpdate();
    });

    instance.on('orientation-change', (options: any) => {
      instance.renderAll();
      const canvas = this.findById(options.id);
      if (canvas && canvas.instance) {
        canvas.width = canvas.instance.getScaledWidth();
        canvas.height = canvas.instance.getScaledHeight();
        canvas.data = canvas.instance?.serialize();
        const thumb = canvas.instance.generateThumb();
        this.props.onCanvasUpdate && this.props.onCanvasUpdate(canvas, thumb);
      }
    });

    instance.on('canvas-add', (options: any) => {
      const canvas = this.findById(options.id);
      if (canvas && canvas.instance) {
        canvas.data = options.data || canvas.instance.serialize();
        const thumb = canvas.instance.generateThumb();
        console.log('thumb', thumb.path.length)
        this.props.onCanvasAdd && this.props.onCanvasAdd(canvas, thumb);
      }
    });
    instance.on('canvas-update', (options: any) => {
      const canvas = this.findById(options.id);
      if (canvas && canvas.instance) {
        canvas.data = canvas.instance?.serialize();
        const thumb = canvas.instance.generateThumb();
        this.props.onCanvasUpdate && this.props.onCanvasUpdate(canvas, thumb);
      }
    });
    instance.on('canvas-delete', (options: any) => {
      this.props.onCanvasDelete && this.props.onCanvasDelete(options.id);
    });

    instance.resize(this.props.availableHeight);

    return instance;
  }

  public addCanvas = (args: NewCanvasArgs): Promise<void> => {
    return new Promise((resolve) => {
      const canvas: Partial<CanvasData> = {
        id: args.id,
        status: 'active',
        width: args.width,
        height: args.height,
        data: args.data || '',
        template: args.template,
        design: args.design,
      }


      args.data && console.log(JSON.parse(args.data));

      const newList = [
        ...this.state.canvases,
        (canvas as CanvasItem)
      ];

      this.setState({
        canvases: newList,
        flags: [],
      }, async () => {
        const instance = await this.createInstance(args);
        console.log('instance.objects', instance.getObjects());

        if (args.data && args.magicResize) {
          instance.magicResize(args.magicResize.fromWidth, args.magicResize.fromHeight, true);
          instance.renderAll();
        }

        newList[ newList.length - 1 ].instance = instance;

        this.setState({
          canvases: newList,
          index: newList.length - 1,
          selectedObjects: [],
          flags: [],
        }, () => {
          instance.fire('canvas-add', { id: args.id });
          resolve();
        })
      });
    })
  }

  public removeCanvas = (index: number) => {
    const newList = [
      ...this.state.canvases
    ];
    const canvas = newList.splice(index, 1);

    const sizeArr = [ this.state.sizeFilter.width, this.state.sizeFilter.height ];
    let newIndex = newList.length > 0 ? newList.length - 1 : undefined;

    for (let i = 0; i < newList.length; i++) {
      if (sizeArr.includes(newList[ i ].width) && sizeArr.includes(newList[ i ].height)) {
        newIndex = i;
      }
    }

    this.setState({
      canvases: newList,
      index: newIndex,
      selectedObjects: [],
      flags: [],
    })

    canvas[ 0 ].instance?.fire('canvas-delete', { id: canvas[ 0 ].id });
    canvas[ 0 ].instance?.dispose();
  }

  public duplicateCanvas = (index: number) => {
    const existing = this.state.canvases[ index ].instance;
    if (!existing) {
      return;
    }

    const id = uniqueId() + '-clone';

    const data = existing.serialize();
    const width = existing.getScaledWidth();
    const height = existing.getScaledHeight();

    const canvas: CanvasItem = {
      id,
      width,
      height,
      data,
    }

    const newList = [
      ...this.state.canvases,
      canvas as CanvasItem,
    ];
    this.setState({
      canvases: newList,
      flags: [],
    }, async () => {

      const instance = await this.createInstance({
        id,
        width,
        height,
        data,
      });

      newList[ newList.length - 1 ].instance = instance;
      this.setState({
        canvases: newList,
        index: newList.length - 1,
        selectedObjects: [],
        flags: [],
      }, () => {
        instance.updated(false);
        instance.fire('canvas-add', { id, data });
      });
    })
  }

  public setActiveCanvas = (newIndex: number) => {
    this.setState({
      index: newIndex,
      selectedObjects: [],
      flags: [],
    })
  }

  public setSizeFilter = (size: SizeFilter) => {
    let newIndex = -1;
    for (let i = 0; i < this.state.canvases.length; i++) {
      if ([ size.width, size.height ].includes(this.state.canvases[ i ].width) && [ size.width, size.height ].includes(this.state.canvases[ i ].height)) {
        newIndex = i;
      }
    }

    this.setState({
      sizeFilter: size,
      index: newIndex > -1 ? newIndex : undefined,
      selectedObjects: [],
      flags: [],
    });
    this.props.onSizeFilterChange && this.props.onSizeFilterChange(size);
  }

  private findById = (id: string) => {
    return this.state.canvases.find(canvas => canvas.id === id);
  }

  private loadFonts = (fontsToLoad: FontLoadInfo[]): Promise<void> => {
    const families: string[] = [];
    const cssPaths: string[] = [];

    fontsToLoad.forEach(font => {
      families.push(font.fontFamily, `${font.fontFamily}:n4`, `${font.fontFamily}:n7`, `${font.fontFamily}:i4`, `${font.fontFamily}:i7`);
      cssPaths.push(`${process.env.CDN_URL}/${font.css}`);
    })


    return new Promise(async (resolve) => {
      if (families.length === 0 || cssPaths.length === 0) {
        resolve();
      }

      const WebFont = await import('webfontloader')
      WebFont.load({
        custom: {
          families: families,
          urls: cssPaths,
        },
        active: () => {
          resolve();
        }
      })
    })
  }

  private addFlag = (flag: CanvasFlag) => {
    this.setState({
      flags: [ ...this.state.flags, flag ]
    })
  }
  private removeFlag = (flag: CanvasFlag) => {
    this.setState({
      flags: this.state.flags.filter(item => item !== flag)
    })
  }

  public render() {
    const context: IContext = {
      canvases: this.state.canvases,
      sizeFilter: this.state.sizeFilter,
      addCanvas: this.addCanvas,
      setActiveCanvas: this.setActiveCanvas,
      removeCanvas: this.removeCanvas,
      duplicateCanvas: this.duplicateCanvas,
      selectedObjects: this.state.selectedObjects,
      activeCanvas: typeof this.state.index !== 'undefined' ? this.state.canvases[ this.state.index ].instance : undefined,
      activeIndex: this.state.index,
      setSizeFilter: this.setSizeFilter,
      fonts: this.state.fonts,
      loadFonts: this.loadFonts,
      defaultFont: this.props.defaultFont,
      flags: this.state.flags,
      addFlag: this.addFlag,
      removeFlag: this.removeFlag,
    }

    return (
      <Context.Provider value={context}>
        {this.props.children}
        <DeviceScreenshotDrop />
        <ObjectSnapping />
      </Context.Provider>
    );
  }

}


export const useCanvas = () => {
  const context = useContext(Context);
  return context;
}