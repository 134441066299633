import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button } from "@material-ui/core";
import { useCanvas } from "../CanvasProvider";
import { fabric } from "fabric";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootGroupProperties: {
        // color: theme.palette.text.secondary,
      },
    }
  ),
);

const GroupProperties: React.FC = () => {
  const classes = useStyles();
  const { selectedObjects, activeCanvas } = useCanvas();

  const group = () => {
    if (!activeCanvas) {
      return;
    }

    const activeObj = activeCanvas.getActiveObject();
    const grouped = (activeObj as any).toGroup() as fabric.Group;
    grouped.name = 'custom_group';
    activeCanvas.discardActiveObject();
    activeCanvas.setActiveObject(grouped);
    activeCanvas.updated();
  }

  const ungroup = () => {
    if (!activeCanvas || !selectedObjects) {
      return;
    }

    const activeObj = selectedObjects[ 0 ];
    const activeSelection = (activeObj as any).toActiveSelection();
    activeCanvas.discardActiveObject();
    const objs = activeSelection._objects;
    var sel = new fabric.ActiveSelection(objs, {
      canvas: activeCanvas,
    });
    activeCanvas.setActiveObject(sel);
    activeCanvas.updated();
  }


  const isGroup = selectedObjects?.length === 1 && selectedObjects[ 0 ].type === 'group';


  return (
    <div className={classes.rootGroupProperties}>
      {
        isGroup ? (
          <Button color="inherit" size="small" onClick={ungroup}>Ungroup</Button>
        ) : (
            <Button color="inherit" size="small" onClick={group}>Group</Button>
          )
      }
    </div>
  );
};

export default GroupProperties;