import { fabric } from "fabric";
import { loadImage, coordsToDirection, directionToCoords } from "../../utils/fabricHelper";

export interface NewCanvasArgs {
  id: string;
  width: number;
  height: number;
  data?: string;
  template?: string;
  design?: string;
  magicResize?: {
    fromWidth: number;
    fromHeight: number;
  };
}


export const createInstance = async (args: NewCanvasArgs): Promise<fabric.Canvas> => {
  const instance = new fabric.Canvas(args.id);

  fabric.Object.prototype.set({
    cornerColor: '#ffffff',
    cornerStrokeColor: '#777777',
    cornerStyle: 'circle',
    borderColor: '#00bcd4',
    // padding: 10,
    transparentCorners: false,
});

  instance.preserveObjectStacking = true;

  instance.domElementId = args.id;

  instance.resize = function (availableHeight: number) {
    let scale = 1;
    let width = args.width;
    let height = args.height;

    if (this.initialResizeDone && (this.getScaledWidth() !== args.width || this.getScaledHeight() !== args.height)) {
      width = this.getScaledWidth();
      height = this.getScaledHeight();
    }

    if (height > width) {
      scale = availableHeight / height;
    }
    else {
      scale = availableHeight / width;
    }

    this.setZoom(scale);
    this.setWidth(width * scale);
    this.setHeight(height * scale);
    this.initialResizeDone = true;
  }

  instance.getScaledWidth = function () {
    const zoom = this.getZoom();
    return zoom > 1 ? this.getWidth() * zoom : this.getWidth() / zoom;
  }
  instance.getScaledHeight = function () {
    const zoom = this.getZoom();
    return zoom > 1 ? this.getHeight() * zoom : this.getHeight() / zoom;
  }
  instance.setBgColor = function (color) {
    this.backgroundImage = 0 as any;
    this.backgroundImage = undefined;
    this.setBackgroundColor(color, () => this.renderAll());
  }
  instance.setBgImage = async function (img) {
    const image = typeof img === 'string' ? await loadImage(img) : img;

    const imgWidth = image.width || 1;
    const imgHeight = image.height || 1;

    const canvasAspect = this.getScaledWidth() / this.getScaledHeight();
    const imgAspect = imgWidth / imgHeight;
    let left, top, scaleFactor;

    if (canvasAspect >= imgAspect) {
      scaleFactor = this.getScaledWidth() / imgWidth;
      left = 0;
      top = -((imgHeight * scaleFactor) - this.getScaledHeight()) / 2;
    } else {
      scaleFactor = this.getScaledHeight() / imgHeight;
      top = 0;
      left = -((imgWidth * scaleFactor) - this.getScaledWidth()) / 2;
    }

    this.backgroundColor = undefined;
    this.setBackgroundImage(image, () => this.renderAll(), {
      top: top,
      left: left,
      originX: 'left',
      originY: 'top',
      scaleX: scaleFactor,
      scaleY: scaleFactor * 1.05
    });
  }
  instance.serialize = function () {
    return JSON.stringify(this.toDatalessJSON([
      'name',
      'data',
      '_controlsVisibility',
      'lockScalingX',
      'lockScalingY',
      'lockUniScaling',
      'lockMovementX',
      'lockMovementY',
      'lockRotation',
      'strokeUniform',
    ]));
  }
  instance.generateThumb = function (size: number = 600) {
    console.time('generateThumb');
    const canvasWidth = this.getWidth();
    const canvasHeight = this.getHeight();
    const scaleFactor = size / canvasHeight;

    const width = Math.round(canvasWidth * scaleFactor);
    const height = Math.round(canvasHeight * scaleFactor);

    const thumb = this.toDataURL({
      multiplier: scaleFactor,
      format: 'png',
    });

    console.timeEnd('generateThumb');
    console.log('scaleFactor', scaleFactor);
    console.log('thumbSize', `${thumb.length / 1024}kb`);
    return {
      path: thumb,
      width,
      height
    };
  }
  instance.undo = function () {
    if (this.undoIndex + 1 >= this.history.length) {
      return;
    }

    this.undoIndex++;
    const json = this.history[ this.history.length - 1 - this.undoIndex ];
    this.loadFromJSON(json, () => {
      this.renderAll();
      this.fire('react-update', {});
      this.fire('canvas-update', { id: this.domElementId });
    })
  }
  instance.redo = function () {
    if (this.undoIndex <= 0) {
      return;
    }
    this.undoIndex--;
    const json = this.history[ this.history.length - 1 - this.undoIndex ];
    this.loadFromJSON(json, () => {
      this.renderAll();
      this.fire('react-update', {});
      this.fire('canvas-update', { id: this.domElementId });
    })
  }
  instance.updated = function (renderAll = true) {
    const data = this.serialize();
    if (this.undoIndex > 0) {
      const index = this.history.length - this.undoIndex;
      this.history.splice(index, this.history.length);
    }
    this.history.push(data);
    this.undoIndex = 0;
    if (renderAll) {
      this.renderAll();
    }
    this.fire('react-update', {});
    this.fire('canvas-update', { id: this.domElementId });
  }
  instance.initHistory = function () {
    this.undoIndex = 0;
    this.history = [ this.serialize() ];
  }
  instance.getOrientation = function () {
    return this.getScaledWidth() > this.getScaledHeight() ? 'landscape' : 'portrait';
  }
  instance.setOrientation = function (orientation) {
    if (orientation === this.getOrientation()) {
      return;
    }

    const oldWidth = this.getScaledWidth();
    const oldHeight = this.getScaledHeight();

    const newWidth = this.getScaledHeight();
    const newHeihght = this.getScaledWidth();

    this.setWidth(newWidth * this.getZoom());
    this.setHeight(newHeihght * this.getZoom());
    this.magicResize(oldWidth, oldHeight);
    this.fire('orientation-change', { id: this.domElementId });
  }
  instance.rescaleObjects = function (ratio) {
    const objects = this.getObjects();
    for (const obj of objects) {
      const currScaleX = obj.scaleX as number
      const currScaleY = obj.scaleY as number
      obj.set({
        scaleX: currScaleX * ratio,
        scaleY: currScaleY * ratio,
      })
    }
  }
  instance.magicResize = function (oldWidth: number, oldHeight: number, rescale?: boolean) {
    if (this.backgroundColor && typeof this.backgroundColor === 'object') {
      const gradient = (this.backgroundColor as any) as fabric.Gradient;
      let coords = gradient.coords as fabric.IGradientOptionsCoords;

      const gradientDirection = coordsToDirection(coords);
      gradient.coords = directionToCoords(gradientDirection, this);
      this.setBgColor(gradient);
    }

    if (this.backgroundImage) {
      this.setBgImage(this.backgroundImage as any);
    }

    const objects = this.getObjects();
    const width = this.getScaledWidth();
    const height = this.getScaledHeight();


    const widthRatio = width / oldWidth;
    const heightRatio = height / oldHeight;
    const scaleRatio = widthRatio > 1 ? Math.max(widthRatio, heightRatio) : Math.min(widthRatio, heightRatio);

    for (const obj of objects) {
      let left = (obj.left as number);
      let top = (obj.top as number);

      const _originX = obj.originX || 'left';
      const _originY = obj.originY || 'top';

      let point = obj.translateToGivenOrigin(new fabric.Point(left, top), _originX, _originY, 'center', 'center');

      const newLeft = point.x * widthRatio;
      const newTop = point.y * heightRatio;


      obj.set({
        originX: 'center',
        originY: 'center',
        left: newLeft,
        top: newTop,
      });

      if (rescale) {
        if (obj.type === 'text' || obj.type === 'textbox') {
          // const newWidth = (obj.width as number) >= this.getWidth() ? this.getWidth() : obj.width;
          (obj as fabric.Textbox).set({
            fontSize: Math.round(((obj as fabric.Textbox).fontSize as number) * Math.max(widthRatio, heightRatio)),
            width: this.getScaledWidth() - 50,
          })
        }
        else {
          obj.scale((obj.scaleX || 1) * scaleRatio);
        }
      }

      obj.setCoords();

      point = obj.translateToGivenOrigin(new fabric.Point(newLeft, newTop), 'center', 'center', _originX, _originY);

      obj.set({
        originX: _originX,
        originY: _originY,
        left: point.x,
        top: point.y,
      })
    }
  }

  // addSnapping(instance);

  return new Promise((resolve, reject) => {
    if (args.data) {
      instance.loadFromJSON(args.data, () => {
        instance.initHistory();
        instance.renderAll();
        resolve(instance);
      });
    }
    else {
      instance.initHistory();
      resolve(instance);
    }
  })
}