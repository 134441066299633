import React, { useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Button, IconButton, Fab } from "@material-ui/core";
import { useCanvas } from "./CanvasProvider";

import IconAdd from '@material-ui/icons/Add';
import IconAddDesign from '@material-ui/icons/LibraryAdd';

import uniqueId from "../../utils/uniqueId";
import { canvasSizeTypes } from "./FilterToggles";


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      addCanvasButton: {
      },
      addIcon: {
        marginRight: theme.spacing(1),
      }
    }
  ),
);

interface Props {
  className?: string;
  extended?: boolean;
}

export const AddCanvasButton: React.FC<Props> = ({ className, extended }) => {
  const classes = useStyles();
  const { addCanvas, sizeFilter } = useCanvas();

  const sizeArr = [ sizeFilter.width, sizeFilter.height ];

  const onAdd = () => {
    let width, height;
    canvasSizeTypes.forEach(type => {
      for (const key in type.subtypes) {
        if (sizeArr.includes(type.subtypes[ key ].width) && sizeArr.includes(type.subtypes[ key ].height)) {
          width = type.subtypes[ key ].width;
          height = type.subtypes[ key ].height;
        }
      }
    })

    if (!width || !height) {
      return;
    }

    addCanvas({
      id: uniqueId(),
      width,
      height,
    })
  }

  if (extended) {
    return (
      <Fab onClick={onAdd} color="primary" size="medium" variant="extended" className={className}>
        <IconAddDesign color="action" className={classes.addIcon} />
        Add Design
      </Fab>
    );
  }

  return (

    <Fab onClick={onAdd} color="primary" className={className}>
      <IconAdd />
    </Fab>

  );
};