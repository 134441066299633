import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, IconButton, Tooltip } from "@material-ui/core";
import IconSendToBack from '@material-ui/icons/FlipToBackRounded';
import IconSendToFront from '@material-ui/icons/FlipToFrontRounded';
import { useCanvas } from "../../CanvasProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootArrangeOrder: {
        display: 'inline-flex',
        flexDirection: 'row',
      },
      space: {
        display: 'inline-block',
        width: theme.spacing(1),
      }
    }
  ),
);

const ArrangeOrder: React.FC = () => {
  const classes = useStyles();
  const { selectedObjects, activeCanvas } = useCanvas();

  const obj = selectedObjects && selectedObjects[ 0 ];

  const changeOrder = (argNewIndex: number) => {
    if (!activeCanvas || !obj) {
      return;
    }

    let objects = activeCanvas.getObjects();


    const index = objects.indexOf(obj);
    if (index === -1) {
      return;
    }

    let newIndex = argNewIndex;
    if (newIndex < 0) {
      newIndex = 0;
    }
    else if (newIndex >= objects.length) {
      newIndex = objects.length;
    }

    const el = objects.splice(index, 1);
    objects.splice(newIndex, 0, el[0]);    

    activeCanvas._objects = [ ...objects ];
    activeCanvas.updated();
  }

  const sendToBack = () => {
    if (!activeCanvas || !obj) {
      return;
    }

    const objects = activeCanvas.getObjects();
    const index = objects.indexOf(obj);

    let nextIntersection = -1;
    for (let i=0; i<index; i++) {
      if (objects[i] !== obj && objects[i].intersectsWithObject(obj)) {
        nextIntersection = i;
      }
    }


    // const nextIntersection = objects.reverse().findIndex(item => item !== obj && item.intersectsWithObject(obj));

    if (nextIntersection === -1) {
      changeOrder(index - 1);
    }
    else {
      changeOrder(nextIntersection);
    }

  }

  const sendToFront = () => {
    if (!activeCanvas || !obj) {
      return;
    }

    const objects = activeCanvas.getObjects();
    const index = objects.indexOf(obj);


    let nextIntersection = -1;
    for (let i=index+1; i<objects.length; i++) {
      if (objects[i] !== obj && objects[i].intersectsWithObject(obj)) {
        nextIntersection = i;
        break;
      }
    }

    if (nextIntersection === -1) {
      changeOrder(index + 1);
    }
    else {
      changeOrder(nextIntersection);
    }
  }

  const objIndex = obj && activeCanvas ? activeCanvas.getObjects().indexOf(obj) : -1;

  const canBringForward = activeCanvas && objIndex < activeCanvas._objects.length - 1;
  const canSendBackward = activeCanvas && objIndex > 0;


  return (
    <React.Fragment>
      <IconButton onClick={sendToBack} disabled={!canSendBackward}>
        <Tooltip title="Backward">
          <IconSendToBack />
        </Tooltip>
      </IconButton>
      <IconButton onClick={sendToFront} disabled={!canBringForward}>
        <Tooltip title="Forward">
          <IconSendToFront />
        </Tooltip>
      </IconButton>
      <div className={classes.space} />
      <div className={classes.space} />
    </React.Fragment>
  );
};

export default ArrangeOrder;