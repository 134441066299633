import React, { useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { TemplateData } from "repix-common";

import { actions } from '../../../services/templates';
import { useDispatch, useSelector } from "react-redux";

import TemplateItem from './TemplateItem';
import { RootState } from "../../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      rootTemplateList: {
      },
      list: {
        display: 'flex',
        flexDirection: 'column',
      },
      tempalteItem: {
        marginBottom: theme.spacing(2),
      }
    }
  ),
);

interface Props {
  onSelect: (template: TemplateData) => void;
  selectedId?: string;
}

const TemplateList: React.FC<Props> = ({ onSelect, selectedId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const templates = useSelector((state: RootState) => state.templates.templates);

  useEffect(() => {
    dispatch(actions.loadList());
  }, []);


  return (
    <div className={classes.rootTemplateList}>
      <div className={classes.list}>
        {
          templates.map(template => (
            <TemplateItem
              selected={template.id === selectedId}
              onClick={onSelect}
              key={template.id}
              template={template}
              className={classes.tempalteItem} />
          ))
        }
      </div>
    </div>
  );
};

export default TemplateList;