import React, { useState } from "react";
import { fabric } from 'fabric';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, ButtonBase, Typography, lighten, darken } from "@material-ui/core";

import clsx from 'clsx';

import IconText from '@material-ui/icons/FormatSizeRounded';
import IconImage from '@material-ui/icons/InsertPhoto';
import IconDevices from '@material-ui/icons/Devices';
import IconElementes from '@material-ui/icons/DashboardOutlined';
import IconFonts from '@material-ui/icons/FontDownloadOutlined';
import { useCanvas } from "../CanvasProvider";

import IconShapes from './icons/shapes.svg';

import TabText from './TabText';
import TabDevices from './TabDevices';
import TabImages from './TabImages';
import TabElements from './TabElements';
import TabShapes from './TabShapes';
import TabFonts from './TabFonts';


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      canvasObjects: {
        display: 'flex',
        flexDirection: 'row',
      },
      spacer: {
        height: theme.spacing(1),
      },
      btn: {
        borderRadius: theme.spacing(0.5),
      },
      tabs: {
        position: 'relative',
        borderRight: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        height: 'calc(100vh - 64px)',
        [theme.breakpoints.down('xs')]: {
          height: 'calc(100vh - 56px)',
        }
      },
      tabsCover: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.default,
        opacity: 0.5,
      },
      tabItem: {
        padding: theme.spacing(1, 2),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        transition: 'all 200ms',
        '&:first-child': {

          paddingTop: theme.spacing(2),
        },
        '&.selected': {
          backgroundColor: darken(theme.palette.background.default, 0.05),
          // backgroundColor: theme.palette.divider,
          color: theme.palette.secondary.main,
        },
      },

      tabPane: {
        flex: 1,
        // padding: theme.spacing(1),
      },

      svgIcon: {
        width: 28,
      }
    }
  ),
);

export const CanvasObjects: React.FC = () => {
  const classes = useStyles();
  const { activeCanvas: canvas, activeIndex } = useCanvas();

  const [ tab, setTab ] = useState(0);

  const renderTab = () => {
    if (!canvas) {
      return null;
    }

    if (tab === 0) {
      return <TabDevices activeCanvas={canvas}/>;
    }
    else if (tab === 1) {
      return <TabText />;
    }
    else if (tab === 2) {
      return <TabImages activeCanvas={canvas}/>;
    }
    else if (tab === 3) {
      return <TabElements activeCanvas={canvas}/>;
    }
    else if (tab === 4) {
      return <TabShapes />;
    }
    else if (tab === 5) {
      return <TabFonts listHeight={window.innerHeight - 140} listWidth={200} />;
    }

    return null;
  }



  const disabled = typeof activeIndex === 'undefined';


  return (
    <div className={classes.canvasObjects}>
      <div className={classes.tabs}>
        <ButtonBase className={clsx(classes.tabItem, { 'selected': tab === 0 })} onClick={() => setTab(0)}>
          <IconDevices color="inherit" />
          <Typography variant="caption">Devices</Typography>
        </ButtonBase>
        <ButtonBase className={clsx(classes.tabItem, { 'selected': tab === 1 })} onClick={() => setTab(1)}>
          <IconText color="inherit" />
          <Typography variant="caption">Text</Typography>
        </ButtonBase>
        <ButtonBase className={clsx(classes.tabItem, { 'selected': tab === 2 })} onClick={() => setTab(2)}>
          <IconImage color="inherit" />
          <Typography variant="caption">Images</Typography>
        </ButtonBase>
        <ButtonBase className={clsx(classes.tabItem, { 'selected': tab === 3 })} onClick={() => setTab(3)}>
          <IconElementes color="inherit" />
          <Typography variant="caption">Elements</Typography>
        </ButtonBase>
        <ButtonBase className={clsx(classes.tabItem, { 'selected': tab === 4 })} onClick={() => setTab(4)}>
          <IconShapes color="inherit" className={classes.svgIcon} />
          <Typography variant="caption">Shapes</Typography>
        </ButtonBase>
        <ButtonBase className={clsx(classes.tabItem, { 'selected': tab === 5 })} onClick={() => setTab(5)}>
          <IconFonts color="inherit" />
          <Typography variant="caption">My Fonts</Typography>
        </ButtonBase>
        {
          disabled ? <div className={classes.tabsCover} /> : null
        }
      </div>
      <div className={classes.tabPane}>
        {
          renderTab()
        }
      </div>
    </div>
  )
};