import React, { useEffect, useState } from "react";
import { useCanvas } from "./CanvasProvider";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { actions, loadDevice as loadDeviceData } from '../../services/devices';
import { Device } from "repix-common";
import { loadDevice } from "../../utils/fabricHelper";

interface OnDropOptions {
  imageObj: fabric.Image;
  deviceObj: fabric.Group;
}

const DeviceScreenshotDrop: React.FC = () => {
  const { activeCanvas, defaultFont } = useCanvas();
  const dispatch = useDispatch();
  const devices = useSelector((state: RootState) => state.devices.devices);

  useEffect(() => {
    dispatch(actions.loadList());
  }, []);



  const resetOpacities = () => {
    if (!activeCanvas) {
      return;
    }

    console.log('set opacity');

    activeCanvas.forEachObject(obj => {
      if (obj.name === 'device') {
        obj.set('opacity', 1);
        activeCanvas.renderAll();
        return;
      }
    })
  }

  useEffect(() => {
    if (!activeCanvas) {
      return;
    }

    const onMoved = async (e: fabric.IEvent) => {
      if (e.target?.type !== 'image') {
        return;
      }

      const objects = activeCanvas.getObjects();

      for (const obj of objects) {
        if (obj.name !== 'device') {
          continue;
        }

        if (e.target.intersectsWithObject(obj)) {
          await onDrop({
            imageObj: e.target as fabric.Image,
            deviceObj: obj as fabric.Group
          })
          return;
        }
      }
    }

    const onMoving = (e: fabric.IEvent) => {
      if (e.target?.type !== 'image') {
        return;
      }

      e.target.setCoords();

      const objects = activeCanvas.getObjects();

      for (const obj of objects) {
        if (obj.name !== 'device') {
          continue;
        }

        if (e.target.intersectsWithObject(obj)) {
          e.target.set('opacity', 0.5);
          return;
          // activeCanvas.renderAll();
        }
        else {
          e.target.set('opacity', 1);
          // activeCanvas.renderAll();
        }
      }
    }

    const onDrop = (async (data: OnDropOptions) => {
      resetOpacities();
      let deviceData: Device | null = data.deviceObj && devices[ data.deviceObj.data.id ];
      if (!deviceData) {
        deviceData = await loadDeviceData(data.deviceObj.data.id);
        if (deviceData) {
          dispatch(actions.setDevice(deviceData));
        }
        else {
          return;
        }
      }

      activeCanvas.remove(data.imageObj);

      const newDevice = await loadDevice({
        device: deviceData,
        color: data.deviceObj.data.color,
        screenshot: data.imageObj.getSrc(),
        orientation: data.deviceObj.data.orientation,
        defaultFont: defaultFont,
      });

      newDevice.set({
        scaleX: data.deviceObj.scaleX,
        scaleY: data.deviceObj.scaleY,
        top: data.deviceObj.top,
        left: data.deviceObj.left,
        originX: data.deviceObj.originX,
        originY: data.deviceObj.originY,
        angle: data.deviceObj.angle,
      });

      const objects = activeCanvas.getObjects();
      const index = objects.indexOf(data.deviceObj);

      activeCanvas.remove(data.deviceObj);
      activeCanvas.add(newDevice);

      if (index !== -1) {
        activeCanvas.moveTo(newDevice, index);
      }
      activeCanvas.setActiveObject(newDevice);
      activeCanvas.updated();
    })

    activeCanvas.on('object:moved', onMoved);
    activeCanvas.on('object:moving', onMoving);


    return () => {
      activeCanvas.off('object:moved', onMoved);
      activeCanvas.off('object:moving', onMoving);
    }

  }, [ activeCanvas ]);

  return null;
};

export default DeviceScreenshotDrop;